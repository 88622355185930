import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  purchases: [],
  purchase: null,
  activeStep: 0,
  page: 0, // Add this to manage pagination
  rowsPerPage: 25, // Set the default rows per page
  totalItems: 0, // Track total items available
  purchasesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  purchaseStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    // GET PURCHASES
    getPurchasesStart(state) {
      state.purchasesStatus.loading = true;
      state.purchasesStatus.empty = false;
      state.purchasesStatus.error = null;
    },
    getPurchasesFailure(state, action) {
      state.purchasesStatus.loading = false;
      state.purchasesStatus.empty = false;
      state.purchasesStatus.error = action.payload;
    },
    getPurchasesSuccess(state, action) {
      const { purchases, totalItems } = action.payload;
      state.purchases = purchases;
      state.totalItems = totalItems;
      state.purchasesStatus.loading = false;
      state.purchasesStatus.empty = !purchases.length;
      state.purchasesStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // POST PURCHASE
    postPurchaseStart(state) {
      state.purchaseStatus.loading = true;
      state.purchaseStatus.error = null;
    },

    postPurchaseFailure(state, action) {
      state.purchaseStatus.loading = false;
      state.purchaseStatus.error = action.payload;
    },

    postPurchaseSuccess(state, action) {
      state.purchase = action.payload;
      state.purchaseStatus.loading = false;
      state.purchaseStatus.error = null;
    },

    // GET PUSCHASE
    getPurchaseStart(state) {
      state.purchaseStatus.loading = true;
      state.purchaseStatus.error = null;
    },

    getPurchaseFailure(state, action) {
      state.purchaseStatus.loading = false;
      state.purchaseStatus.error = action.payload;
    },

    getPurchaseSuccess(state, action) {
      state.purchase = action.payload;
      state.purchaseStatus.loading = false;
      state.purchaseStatus.error = null;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    resetPargination(state) {
      state.page = 0;
      state.rowsPerPage = 25;
    },
  },
});

// ----------------------------------------------------------------------

// reducers
export default slice.reducer;

// actions
export const { backStep, nextStep, gotoStep, setPage, setRowsPerPage, resetPargination } =
  slice.actions;

export const getPurchases = (params) => async (dispatch) => {
  try {
    dispatch(slice.actions.getPurchasesStart());
    const response = await axios.get(API_ENDPOINTS.purchases.list, { params });
    console.log('GET PURCHASES RESPONSE', response.data);
    const { results, count } = response.data;
    dispatch(
      slice.actions.getPurchasesSuccess({
        purchases: results,
        totalItems: count,
      })
    );
  } catch (error) {
    dispatch(slice.actions.getPurchasesFailure(error.message || 'Something went wrong'));
  }
};

export const getPurchase = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.getPurchaseStart());
    const response = await axios.get(API_ENDPOINTS.purchases.details(id));
    console.log('GET PURCHASE RESPONSE', response.data);
    dispatch(slice.actions.getPurchaseSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.getPurchasesFailure(error.message || 'Something went wrong'));
  }
};

export const postPurchase = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.postPurchaseStart());
    const response = await axios.post(API_ENDPOINTS.purchases.post, data);
    console.log('POST PURCHASE RESPONSE', response.data);
    dispatch(slice.actions.postPurchaseSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.postPurchaseFailure(error));
  }
};

export function syncPurchasesFromEtims({ data = { lastReqDt: '20200101000000' } }) {
  return async () => {
    try {
      console.log('Starting purchase synchronization with eTIMS...');
      const response = await axios.post(API_ENDPOINTS.purchases.sync, data);
      console.log('Purchase sync successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to sync purchases from eTIMS:', error);
      return error;
    }
  };
}
