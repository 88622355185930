import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import sanitizeData from 'src/utils/sanitize-data';
// ----------------------------------------------------------------------

const initialState = {
  products: [],
  product: null,
  activeStep: 0,
  page: 0,
  rowsPerPage: 5, // Changed from 25 to 5 as requested
  totalItems: 0,
  defaultOrderBy: 'id', // Added default ordering
  orderDirection: 'desc', // Added default direction
  productsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  productStatus: {
    loading: false,
    error: null,
  },
  filters: {
    searchQuery: '',
    category: 'all',
    // Add more filters as needed
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // GET PRODUCTS
    getProductsStart(state) {
      state.productsStatus.loading = true;
      state.productsStatus.empty = false;
      state.productsStatus.error = null;
    },
    getProductsFailure(state, action) {
      state.productsStatus.loading = false;
      state.productsStatus.empty = false;
      state.productsStatus.error = action.payload;
    },
    getProductsSuccess(state, action) {
      const { products, totalItems } = action.payload;
      state.products = products;
      state.totalItems = totalItems;
      state.productsStatus.loading = false;
      state.productsStatus.empty = !products.length;
      state.productsStatus.error = null;
    },

    // GET PRODUCT
    getProductStart(state) {
      state.productStatus.loading = true;
      state.productStatus.error = null;
    },
    getProductFailure(state, action) {
      state.productStatus.loading = false;
      state.productStatus.error = action.payload;
    },
    getProductSuccess(state, action) {
      state.product = action.payload;
      state.productStatus.loading = false;
      state.productStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // POST PRODUCT
    postProductStart(state) {
      state.productStatus.loading = true;
      state.productStatus.error = null;
    },

    postProductFailure(state, action) {
      state.productStatus.loading = false;
      state.productStatus.error = action.payload;
    },

    postProductSuccess(state, action) {
      const product = action.payload;
      state.product = product;
      state.productStatus.loading = false;
      state.productStatus.error = null;
      state.productsStatus.empty = false;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    // Handle sorting
    setOrderBy(state, action) {
      const field = action.payload;
      // If clicking the same field, toggle direction
      if (field === state.defaultOrderBy) {
        state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc';
      } else {
        // New field, default to descending
        state.defaultOrderBy = field;
        state.orderDirection = 'desc';
      }
    },

    // Handle search and filtering
    setFilter(state, action) {
      const { name, value } = action.payload;
      state.filters[name] = value;
      // Reset pagination when filters change
      state.page = 0;
    },

    // Handle reset of pagination
    resetPagination(state) {
      state.page = 0;
      state.rowsPerPage = 5; // Changed to match default
    },

    // Clear all filters
    clearFilters(state) {
      state.filters = initialState.filters;
      state.page = 0;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getProductsStart,
  getProductsFailure,
  getProductsSuccess,
  getProductStart,
  getProductFailure,
  getProductSuccess,
  postProductStart,
  postProductFailure,
  postProductSuccess,
  gotoStep,
  backStep,
  nextStep,
  setRowsPerPage,
  setPage,
  setOrderBy,
  setFilter,
  resetPagination,
  clearFilters,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(options = {}) {
  return async (dispatch, getState) => {
    const { page, rowsPerPage, defaultOrderBy, orderDirection, filters } = getState().product;

    dispatch(slice.actions.getProductsStart());

    try {
      // Construct ordering string
      const ordering = `${orderDirection === 'desc' ? '-' : ''}${defaultOrderBy}`;

      const response = await axios.get(API_ENDPOINTS.product.list, {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering,
          search: filters.searchQuery || undefined,
          category: filters.category !== 'all' ? filters.category : undefined,
          ...options,
        },
      });

      console.log('GET PRODUCTS RESPONSE', response.data);

      // Assuming response.data contains total items in 'count'
      const { results, count } = response.data;

      dispatch(
        slice.actions.getProductsSuccess({
          products: results,
          totalItems: count,
        })
      );

      return response.data;
    } catch (error) {
      console.error('GET PRODUCTS ERROR:', error);
      dispatch(slice.actions.getProductsFailure(error.message || 'Error fetching products'));
      return error;
    }
  };
}

export function getProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.getProductStart());
    try {
      const response = await axios.get(API_ENDPOINTS.product.details(id));
      console.log('GET PRODUCT', response.data);
      dispatch(slice.actions.getProductSuccess(response.data));
      return response.data;
    } catch (error) {
      console.error('GET PRODUCT ERROR:', error);
      dispatch(slice.actions.getProductFailure(error.message || 'Error fetching product'));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function createProduct(product) {
  return async (dispatch) => {
    const sanitizedData = sanitizeData(product);
    dispatch(slice.actions.postProductStart());
    try {
      const response = await axios.post(API_ENDPOINTS.product.post, sanitizedData);
      console.log('POST PRODUCT RESPONSE', response.data);
      dispatch(slice.actions.postProductSuccess(response.data.product));
      // Refresh the product list after creating
      dispatch(getProducts());
      return response.data;
    } catch (error) {
      console.error('CREATE PRODUCT ERROR:', error.response?.data || error.message);
      dispatch(slice.actions.postProductFailure(error.message || 'Error creating new product'));
      return error;
    }
  };
}

export function updateProduct(product) {
  return async (dispatch) => {
    const sanitizedData = sanitizeData(product);
    dispatch(slice.actions.postProductStart());
    try {
      const response = await axios.post(API_ENDPOINTS.product.post, sanitizedData);
      console.log('UPDATE PRODUCT RESPONSE', response.data);
      dispatch(slice.actions.postProductSuccess(response.data.product));
      // Refresh the product list after updating
      dispatch(getProducts());
      return response.data;
    } catch (error) {
      console.error('UPDATE PRODUCT ERROR:', error.response?.data || error.message);
      dispatch(slice.actions.postProductFailure(error.message || 'Error updating product'));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function syncProductsFromEtims({ data = { lastReqDt: '20200101000000' } }) {
  return async (dispatch) => {
    try {
      console.log('Starting item/product synchronization with eTIMS...');
      const response = await axios.post(API_ENDPOINTS.product.sync, data);
      console.log('Item/product sync successful:', response.data);
      // Refresh product list after sync
      dispatch(getProducts());
      return response.data;
    } catch (error) {
      console.error(
        'Failed to sync item/product from eTIMS:',
        error.response?.data || error.message
      );
      return error;
    }
  };
}

// Helper to export all product data
export function exportProducts() {
  return async () => {
    try {
      // This endpoint should return all products without pagination
      const response = await axios.get(API_ENDPOINTS.product.export);
      return response.data;
    } catch (error) {
      console.error('EXPORT PRODUCTS ERROR:', error);
      return error;
    }
  };
}
