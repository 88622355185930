import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------


const initialState = {
    customers: null,
    insurances: null,
    products: null,
    sales: null,
    users: null
};

const slice = createSlice({
    name: 'summary',
    initialState,
    reducers: {
        // GET SUMMARY CUSTOMERS
        getSummaryCustomersStart(state) {
            state.customers = null;
        },
        getSummaryCustomersFailure(state, action) {
            state.customers = null;
        },
        getSummaryCustomersSuccess(state, action) {
            state.customers = action.payload;
        },


        // GET SUMMARY INSURANCES
        getSummaryInsurancesStart(state) {
            state.insurances = null;
        },
        getSummaryInsurancesFailure(state, action) {
            state.insurances = null;
        },
        getSummaryInsurancesSuccess(state, action) {
            state.insurances = action.payload;
        },

        // GET SUMMARY PRODUCTS
        getSummaryProductsStart(state) {
            state.products = null;
        },
        getSummaryProductsFailure(state, action) {
            state.products = null;
        },
        getSummaryProductsSuccess(state, action) {
            state.products = action.payload;
        },

        // GET SUMMARY SALES
        getSummarySalesStart(state) {
            state.sales = null;
        },
        getSummarySalesFailure(state, action) {
            state.sales = null;
        },
        getSummarySalesSuccess(state, action) {
            state.sales = action.payload;
        },

        // GET SUMMARY USERS
        getSummaryUsersStart(state) {
            state.users = null;
        },
        getSummaryUsersFailure(state, action) {
            state.users = null;
        },
        getSummaryUsersSuccess(state, action) {
            state.users = action.payload;
        },
    }
})


export default slice.reducer;

// ----------------------------------------------------------------------

// Actions
export const {
    getSummaryCustomersStart,
    getSummaryCustomersFailure,
    getSummaryCustomersSuccess,
    getSummaryInsurancesStart,
    getSummaryInsurancesFailure,
    getSummaryInsurancesSuccess,
    getSummaryProductsStart,
    getSummaryProductsFailure,
    getSummaryProductsSuccess,
    getSummarySalesStart,
    getSummarySalesFailure,
    getSummarySalesSuccess,
    getSummaryUsersStart,
    getSummaryUsersFailure,
    getSummaryUsersSuccess
} = slice.actions;

// ----------------------------------------------------------------------


export function getSummaryCustomers() {
    return async (dispatch) => {
        dispatch(getSummaryCustomersStart());
        try {
            const response = await axios.get(API_ENDPOINTS.summary.customers);
            console.log("GET SUMMARY CUSTOMERS", response.data);
            dispatch(getSummaryCustomersSuccess(response.data));
        } catch (error) {
            dispatch(getSummaryCustomersFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getSummaryInsurances() {
    return async (dispatch) => {
        dispatch(getSummaryInsurancesStart());
        try {
            const response = await axios.get(API_ENDPOINTS.summary.insurances);
            dispatch(getSummaryInsurancesSuccess(response.data));
            console.log("GET SUMMARY INSURANCES", response.data);
        } catch (error) {
            dispatch(getSummaryInsurancesFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getSummaryProducts() {
    return async (dispatch) => {
        dispatch(getSummaryProductsStart());
        try {
            const response = await axios.get(API_ENDPOINTS.summary.products);
            console.log("GET SUMMARY PRODUCTS", response.data);
            dispatch(getSummaryProductsSuccess(response.data));
        } catch (error) {
            dispatch(getSummaryProductsFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getSummarySales() {
    return async (dispatch) => {
        dispatch(getSummarySalesStart());
        try {
            const response = await axios.get(API_ENDPOINTS.summary.sales);
            console.log("GET SUMMARY SALES", response.data);
            dispatch(getSummarySalesSuccess(response.data));
        } catch (error) {
            dispatch(getSummarySalesFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getSummaryUsers() {
    return async (dispatch) => {
        dispatch(getSummaryUsersStart());
        try {
            const response = await axios.get(API_ENDPOINTS.summary.users);
            console.log("GET SUMMARY USERS", response.data);
            dispatch(getSummaryUsersSuccess(response.data));
        } catch (error) {
            dispatch(getSummaryUsersFailure(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getSummary() {
    return async (dispatch) => {
        dispatch(getSummaryCustomers());
        dispatch(getSummaryInsurances());
        dispatch(getSummaryProducts());
        dispatch(getSummarySales());
        dispatch(getSummaryUsers());
    };
}   