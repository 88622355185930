import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { el } from 'date-fns/locale';
import { element } from 'prop-types';

// ----------------------------------------------------------------------
// Dashboard pages
const IndexPage = lazy(() => import('src/pages/dashboard/dashboard'));

// Sales pages
const NewSale = lazy(() => import('src/pages/dashboard/sales/new-sale'));
const SalesList = lazy(() => import('src/pages/dashboard/sales/sales-list'));
const SaleDetails = lazy(() => import('src/pages/dashboard/sales/sale-details'));

// item pages
const NewItem = lazy(() => import('src/pages/dashboard/items/new-item'));
const ItemList = lazy(() => import('src/pages/dashboard/items/item-list'));
const ItemDetails = lazy(() => import('src/pages/dashboard/items/item-details'));
const EditItem = lazy(() => import('src/pages/dashboard/items/edit-item'));

// Item Composition pages
const NewItemComposition = lazy(() =>
  import('src/pages/dashboard/item-composition/new-item-composition')
);
const ItemCompositionList = lazy(() =>
  import('src/pages/dashboard/item-composition/item-composition-list')
);
const ItemCompositionDetails = lazy(() =>
  import('src/pages/dashboard/item-composition/item-composition-details')
);
const EditItemComposition = lazy(() =>
  import('src/pages/dashboard/item-composition/edit-item-composition')
);

// Purchase pages
const NewPurchase = lazy(() => import('src/pages/dashboard/purchase/new-purchase'));
const PurchaseList = lazy(() => import('src/pages/dashboard/purchase/purchase-list'));
const PurchaseDetails = lazy(() => import('src/pages/dashboard/purchase/purchase-details'));

// Stock pages
const NewStock = lazy(() => import('src/pages/dashboard/stock/new-stock'));
const StockList = lazy(() => import('src/pages/dashboard/stock/stock-list'));
const StockDetails = lazy(() => import('src/pages/dashboard/stock/stock-details'));
const StockItemList = lazy(() => import('src/pages/dashboard/stock/stock-items-list'));
const StockItemDetails = lazy(() => import('src/pages/dashboard/stock/stock-items-details'));

// People pages
const NewCustomer = lazy(() => import('src/pages/dashboard/people/customer/new-customer'));
const EditCustomer = lazy(() => import('src/pages/dashboard/people/customer/edit-customer'));
const CustomerList = lazy(() => import('src/pages/dashboard/people/customer/customer-list'));
const NewSupplier = lazy(() => import('src/pages/dashboard/people/supplier/new-supplier'));
const EditSupplier = lazy(() => import('src/pages/dashboard/people/supplier/edit-supplier'));
const SupplierList = lazy(() => import('src/pages/dashboard/people/supplier/supplier-list'));
const NewUser = lazy(() => import('src/pages/dashboard/people/user/new-user'));
const EditUser = lazy(() => import('src/pages/dashboard/people/user/edit-user'));
const UserList = lazy(() => import('src/pages/dashboard/people/user/user-list'));

// Insurance pages
const InsuranceList = lazy(() => import('src/pages/dashboard/insurance/insurance-list'));
const NewInsurance = lazy(() => import('src/pages/dashboard/insurance/new-insurance'));
const InsuranceDetails = lazy(() => import('src/pages/dashboard/insurance/insurance-details'));
const EditInsurance = lazy(() => import('src/pages/dashboard/insurance/edit-insurance'));

// Settings pages
const CompanySettings = lazy(() => import('src/pages/dashboard/settings/company'));
const ProfileSettings = lazy(() => import('src/pages/dashboard/settings/profile'));
const BillingSettings = lazy(() => import('src/pages/dashboard/settings/billing'));
const IntegrationSettings = lazy(() => import('src/pages/dashboard/settings/integration'));
const NotificationSettings = lazy(() => import('src/pages/dashboard/settings/notification'));
const SecuritySettings = lazy(() => import('src/pages/dashboard/settings/security'));
const HelpSettings = lazy(() => import('src/pages/dashboard/settings/help'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'sales',
        children: [
          { element: <SalesList />, index: true },
          { path: 'new-sale', element: <NewSale /> },
          { path: 'sales-list', element: <SalesList /> },
          { path: ':id', element: <SaleDetails /> },
        ],
      },
      {
        path: 'items',
        children: [
          { element: <ItemList />, index: true },
          { path: ':id', element: <ItemDetails /> },
          { path: 'new-item', element: <NewItem /> },
          { path: 'item-list', element: <ItemList /> },
          { path: 'edit-item/:id', element: <EditItem /> },
          // Item Composition
          { path: 'new-item-composition', element: <NewItemComposition /> },
          { path: 'item-composition-list', element: <ItemCompositionList /> },
          { path: 'item-composition/:id', element: <ItemCompositionDetails /> },
          { path: 'edit-item-composition/:id', element: <EditItemComposition /> },
        ],
      },
      {
        path: 'purchase',
        children: [
          { element: <PurchaseList />, index: true },
          { path: 'new-purchase', element: <NewPurchase /> },
          { path: 'purchase-list', element: <PurchaseList /> },
          { path: ':id', element: <PurchaseDetails /> },
        ],
      },
      {
        path: 'stock',
        children: [
          { element: <StockList />, index: true },
          { path: 'new-stock', element: <NewStock /> },
          { path: 'stock-list', element: <StockList /> },
          { path: ':id', element: <StockDetails /> },
          { path: 'stock-item-list', element: <StockItemList /> },
          { path: 'stock-item/:id', element: <StockItemDetails /> },
        ],
      },
      {
        path: 'people',
        children: [
          { element: <CustomerList />, index: true },
          { path: 'new-customer', element: <NewCustomer /> },
          { path: 'edit-customer/:id', element: <EditCustomer /> },
          { path: 'customer-list', element: <CustomerList /> },
          { path: 'new-supplier', element: <NewSupplier /> },
          { path: 'edit-supplier/:id', element: <EditSupplier /> },
          { path: 'supplier-list', element: <SupplierList /> },
          { path: 'new-user', element: <NewUser /> },
          { path: 'edit-user/:id', element: <EditUser /> },
          { path: 'user-list', element: <UserList /> },
        ],
      },
      {
        path: 'insurance',
        children: [
          { element: <InsuranceList />, index: true },
          { path: 'new-insurance', element: <NewInsurance /> },
          { path: 'insurance-list', element: <InsuranceList /> },
          { path: ':id', element: <InsuranceDetails /> },
          { path: 'edit-insurance/:id', element: <EditInsurance /> },
        ],
      },
      {
        path: 'settings',
        children: [
          { element: <ProfileSettings />, index: true },
          { path: 'company', element: <CompanySettings /> },
          { path: 'profile', element: <ProfileSettings /> },
          { path: 'billing', element: <BillingSettings /> },
          { path: 'integration', element: <IntegrationSettings /> },
          { path: 'notification', element: <NotificationSettings /> },
          { path: 'security', element: <SecuritySettings /> },
          { path: 'help', element: <HelpSettings /> },
        ],
      },
    ],
  },
];
