import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const AUTH_TOKEN_KEY = 'accessToken';
const SCHEMA_NAME_KEY = 'schemaName';
const INIT_INFO_ID_KEY = 'initInfoId';
const ETIMS_PROTOCOL_KEY = 'etimsProtocol';
const ETIMS_ENVIRONMENT_KEY = 'etimsEnvironment';
const ETIMS_VSCU_STATUS_KEY = 'etimsVscuStatus';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
      const schemaName = sessionStorage.getItem(SCHEMA_NAME_KEY);
      const initInfoId = sessionStorage.getItem(INIT_INFO_ID_KEY);
      const etimsProtocol = sessionStorage.getItem(ETIMS_PROTOCOL_KEY);
      const etimsEnvironment = sessionStorage.getItem(ETIMS_ENVIRONMENT_KEY);
      const etimsVscuStatus = sessionStorage.getItem(ETIMS_VSCU_STATUS_KEY);

      if (accessToken) {
        // TODO: will handle check token expired here later
        // isValidToken(accessToken)

        setSession(
          accessToken,
          schemaName,
          initInfoId,
          etimsProtocol,
          etimsEnvironment,
          etimsVscuStatus
        );

        const response = await axios.get(API_ENDPOINTS.auth.me);

        console.log('GET ME', response.data);

        // save access etims_protocol, etims_environment, etims_vscu_status

        const user = response.data?.data.user || null;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(
    async (username, password) => {
      try {
        const data = {
          username,
          password,
        };

        const response = await axios.post(API_ENDPOINTS.auth.login, data);

        console.log('LOGIN', response.data);

        const { token, user, access } = response.data?.data || {};

        if (access && access.length > 0) {
          const { tenant } = access[0]; // We want the first access object
          const {
            schema_name,
            default_init_info,
            etims_protocol,
            etims_environment,
            etims_vscu_status,
          } = tenant;
          setSession(
            token,
            schema_name || 'demo',
            default_init_info || 1,
            etims_protocol,
            etims_environment,
            etims_vscu_status
          ); // Access tenant details from the first access element
          dispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          });
        } else {
          // If access data is missing or empty
          throw new Error('Access data is missing or empty');
        }

        return true; // Successfully logged in
      } catch (error) {
        // If login failed
        console.error('Login failed:', error);
        throw new Error(error.message || 'Login failed');
      }
    },
    [dispatch]
  );

  // REGISTER
  const register = useCallback(async (email, password, username) => {
    try {
      const data = {
        email,
        password,
        username,
      };

      const response = await axios.post(API_ENDPOINTS.auth.register, data);

      const { user, token, access } = response.data?.data || {};

      if (access && access.length > 0) {
        const { tenant } = access[0]; // We want the first access object
        const {
          schema_name,
          default_init_info,
          etims_protocol,
          etims_environment,
          etims_vscu_status,
        } = tenant;
        setSession(
          token,
          schema_name || 'demo',
          default_init_info || 1,
          etims_protocol,
          etims_environment,
          etims_vscu_status
        ); // Access tenant details from the first access element
        dispatch({
          type: 'REGISTER',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'REGISTER',
          payload: {
            user: null,
          },
        });
        // If access data is missing or empty
        throw new Error('Access data is missing or empty');
      }
    } catch (error) {
      // If registration failed
      console.error('Registration failed:', error);
      throw new Error(error.message || 'Registration failed');
    }
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';
  // const checkAuthenticated = 'authenticated'

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      init_info_id: sessionStorage.getItem(INIT_INFO_ID_KEY),
      schema_name: sessionStorage.getItem(SCHEMA_NAME_KEY),

      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
