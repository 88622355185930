import { createSlice } from '@reduxjs/toolkit';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  branches: [],
  branche: null,
  activeStep: 0,
  page: 0, // Add this to manage pagination
  rowsPerPage: 25, // Set the default rows per page
  totalItems: 0, // Track total items available
  branchesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  branchStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    // GET BRANCHES
    getBranchesStart(state) {
      state.branchesStatus.loading = true;
      state.branchesStatus.empty = false;
      state.branchesStatus.error = null;
    },

    getBranchesFailure(state, action) {
      state.branchesStatus.loading = false;
      state.branchesStatus.empty = false;
      state.branchesStatus.error = action.payload;
    },

    getBranchesSuccess(state, action) {
      const { branches, totalItems } = action.payload;
      state.branches = branches;
      state.totalItems = totalItems;
      state.branchesStatus.loading = false;
      state.branchesStatus.empty = !branches.length;
      state.branchesStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    resetPargination(state) {
      state.page = 0;
      state.rowsPerPage = 25;
    },

    // GET BRANCH

    getBranchStart(state) {
      state.branchStatus.loading = true;
      state.branchStatus.error = null;
    },

    getBranchFailure(state, action) {
      state.branchStatus.loading = false;
      state.branchStatus.error = action.payload;
    },

    getBranchSuccess(state, action) {
      state.purchase = action.payload;
      state.branchStatus.loading = false;
      state.branchStatus.error = null;
    },
  },
});

export default slice.reducer;

// Actions

export const {
  getBranchesStart,
  getBranchesFailure,
  getBranchesSuccess,
  backStep,
  nextStep,
  gotoStep,
  setPage,
  setRowsPerPage,
  resetPargination,
  getBranchStart,
  getBranchFailure,
  getBranchSuccess,
} = slice.actions;

//
export const getBranches = (params) => async (dispatch) => {
  try {
    dispatch(slice.actions.getBranchesStart());
    const response = await axios.get(API_ENDPOINTS.branch.list, { params });
    console.log('GET BRANCHES RESPONSE', response.data);
    const { results, count } = response.data;
    dispatch(
      slice.actions.getBranchesSuccess({
        branches: results,
        totalItems: count,
      })
    );
  } catch (error) {
    dispatch(slice.actions.getBranchesFailure(error.message || 'Something went wrong'));
  }
};

// Thunk for synchronizing branches
export function syncBranchesFromEtims({ data = { lastReqDt: '20200101000000' } } = {}) {
  return async () => {
    try {
      console.log('Starting branches synchronization with eTIMS...');
      const response = await axios.post(API_ENDPOINTS.branch.sync, data);
      console.log('Branches sync successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to sync branches from eTIMS:', error);
      return error;
    }
  };
}
