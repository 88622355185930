import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import sanitizeData from 'src/utils/sanitize-data';
// ----------------------------------------------------------------------

const initialState = {
  integrations: [],
  integration: null,
  activeStep: 0,
  page: 0, // Add this to manage pagination
  rowsPerPage: 25, // Set the default rows per page
  totalItems: 0, // Track total items available
  integrationStatus: {
    loading: false,
    error: null,
  },
  integrationsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    // GET INTEGRATIONS
    getIntegrationsStart(state) {
      state.integrationsStatus.loading = true;
      state.integrationsStatus.empty = false;
      state.integrationsStatus.error = null;
    },
    getIntegrationsFailure(state, action) {
      state.integrationsStatus.loading = false;
      state.integrationsStatus.empty = false;
      state.integrationsStatus.error = action.payload;
    },
    getIntegrationsSuccess(state, action) {
      const { integrations, totalItems } = action.payload;
      state.integrations = integrations;
      state.totalItems = totalItems;
      state.integrationsStatus.loading = false;
      state.integrationsStatus.empty = !integrations.length;
      state.integrationsStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // GET INTEGRATION
    getIntegrationStart(state) {
      state.integrationStatus.loading = true;
      state.integrationStatus.error = null;
    },
    getIntegrationFailure(state, action) {
      state.integrationStatus.loading = false;
      state.integrationStatus.error = action.payload;
    },
    getIntegrationSuccess(state, action) {
      state.integration = action.payload;
      state.integrationStatus.loading = false;
    },

    // POST INTEGRATION
    postIntegrationStart(state) {
      state.integrationStatus.loading = true;
      state.integrationStatus.error = null;
    },

    postIntegrationFailure(state, action) {
      state.integrationStatus.loading = false;
      state.integrationStatus.error = action.payload;
    },

    postIntegrationSuccess(state, action) {
      const integration = action.payload;
      state.integration = integration;
      state.integrationStatus.loading = false;
      state.integrationStatus.error = null;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    // reset
    resetPagination(state) {
      state.page = 0;
      state.rowsPerPage = 25;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getIntegrationsStart,
  getIntegrationsSuccess,
  getIntegrationsFailure,

  backStep,
  nextStep,
  gotoStep,

  getIntegrationStart,
  getIntegrationSuccess,
  getIntegrationFailure,

  postIntegrationStart,
  postIntegrationFailure,
  postIntegrationSuccess,

  setPage,
  setRowsPerPage,
  resetPagination,
} = slice.actions;

// ----------------------------------------------------------------------

export function getIntegrations(options = {}) {
  return async (dispatch, getState) => {
    const { page, rowsPerPage } = getState().product;

    dispatch(getIntegrationsStart());
    try {
      const response = await axios.get(API_ENDPOINTS.initialize.list, {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering: '-id',
          ...options,
        },
      });
      console.log('GET INITs RESPONSE', response.data);
      const { results, count } = response.data;
      dispatch(getIntegrationsSuccess({ integrations: results, totalItems: count }));
    } catch (error) {
      dispatch(getIntegrationsFailure(error.message));
    }
  };
}

export function getIntegration(id) {
  return async (dispatch) => {
    dispatch(getIntegrationStart());
    try {
      const response = await axios.get(API_ENDPOINTS.initialize.details(id));
      console.log('GET INIT RESPONSE', response.data);
      dispatch(getIntegrationSuccess(response.data?.data));
    } catch (error) {
      dispatch(getIntegrationFailure(error.message));
    }
  };
}

export function postIntegration(data) {
  return async (dispatch) => {
    const sanitizedData = sanitizeData(data);
    dispatch(slice.actions.postIntegrationStart());
    try {
      const response = await axios.post(API_ENDPOINTS.initialize.post, sanitizedData);
      console.log('POST INIT RESPONSE', response.data);
      dispatch(slice.actions.postIntegrationSuccess(response.data));
      return response.data;
    } catch (error) {
      console.error('POST INIT ERROR', error);
      dispatch(slice.actions.postIntegrationFailure(error) || 'Error initializing integration');
      return error;
    }
  };
}
