// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (token) => {
  if (!token || typeof token !== 'string') {
    return false;
  }

  try {
    const decoded = jwtDecode(token); // Ensure this doesn't break on an invalid token
    return decoded && Date.now() < decoded.exp * 1000;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken, schemaName, initInfoId, etimsProtocol = '', etimsEnvironment = '', etimsVscuStatus = '') => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('schemaName', schemaName);
    sessionStorage.setItem('initInfoId', initInfoId);
    sessionStorage.setItem('etimsProtocol', etimsProtocol);
    sessionStorage.setItem('etimsEnvironment', etimsEnvironment);
    sessionStorage.setItem('etimsVscuStatus', etimsVscuStatus);

    axios.defaults.headers.common.Authorization = `Token ${accessToken}`;
    axios.defaults.headers.common['X-Schema-Name'] = schemaName;
    axios.defaults.headers.common['X-InitInfo-ID'] = initInfoId;
    // TODO: will handle check token expired here later
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    // tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('schemaName');
    sessionStorage.removeItem('initInfoId');
    sessionStorage.removeItem('etimsProtocol');
    sessionStorage.removeItem('etimsEnvironment');
    sessionStorage.removeItem('etimsVscuStatus');

    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common['X-Schema-Name'];
    delete axios.defaults.headers.common['X-InitInfo-ID'];
  }
};