export const selectItemsClass = [
  {
    itemClsCd: '99000000',
    itemClsNm: 'VAT Act',
    itemClsLvl: 1,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99010000',
    itemClsNm: 'Goods',
    itemClsLvl: 2,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011000',
    itemClsNm: 'Exempt Goods (Paragraph 1 - 99)',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011001',
    itemClsNm: 'Bovine Semen of tariff number 05111000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011002',
    itemClsNm: 'Fish eggs and roes of tariff number 0511990110',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011003',
    itemClsNm: 'Animal semen other than of bovine of tariff number 0511999010',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011004',
    itemClsNm: 'Soya beans whether or not broken of tariff numbers 12011000 and 12019000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011005',
    itemClsNm: 'Groundnuts not roasted or otherwise cooked In shell of tariff number 12024100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011006',
    itemClsNm:
      'Groundnuts not roasted or otherwise cooked Shelled whether or not broken of tariff number 12024200',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011007',
    itemClsNm: 'Copra of tariff number 12030000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011008',
    itemClsNm: 'Linseed whether or not broken of tariff number 12040000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011009',
    itemClsNm: 'Low erucic acid rape or colza seed of tariff number 12051000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011010',
    itemClsNm: 'Other rape or colza seed of tariff number  12059000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011011',
    itemClsNm: 'Sunflower seeds whether or not broken of tariff number 12060000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011012',
    itemClsNm: 'Cotton seeds  whether or not broken  Seed of tariff numbers 12072100 and 12072900',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011013',
    itemClsNm: 'Sesamum seeds whether or not broken of tariff number 12074000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011014',
    itemClsNm: 'Mustard seeds whether or  not broken of tariff number 12075000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011015',
    itemClsNm: 'Safflower seeds  whether or  not broken 12076000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011016',
    itemClsNm:
      'Other oil seeds and oleaginous fruits whether or not broken of tariff number 12079900',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011017',
    itemClsNm:
      'Sugarcane of tariff number 1212990300;Sugarcane of tariff number 1212990300;Unprocessed produce of plant species camellia sinensis',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011018',
    itemClsNm: 'Live animals of chapter 1',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011019',
    itemClsNm: 'Meat and edible offals of chapter 2 excluding those of heading 0209 and 0210',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011020',
    itemClsNm:
      'Fish and crustaceans molluscs and other aquatic invertebrates of chapter 3 excluding those of tariff heading 0305 0306 and 0307',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011021',
    itemClsNm: 'Unprocessed milk',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011022',
    itemClsNm: 'Fresh birds eggs in shell',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011023',
    itemClsNm:
      'Edible Vegetables and certain roots and tubers of Chapter 7 excluding those of tariff heading 0711',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011024',
    itemClsNm:
      'Edible fruits and nuts peal of citrus fruits or melon of chapter 8 excluding those of tariff heading 0811 0812 0813 and 0814',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011025',
    itemClsNm: 'Cereals of chapter 10 excluding seeds of tariff heading 1002 ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011032',
    itemClsNm: 'Syringes with or without needles of tarrif no 90183100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011035',
    itemClsNm: 'Tubular metal needles and needles for sutures of tariff number 90183200',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011036',
    itemClsNm: 'Catheters cannulae and the like of tariff number 90183900',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011037',
    itemClsNm: 'Blood bags',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011038',
    itemClsNm: 'Blood and fluid infusion sets',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011039',
    itemClsNm:
      'Materials articles and equipment including motor vehicles listed under the first schedule paragraph 39 of the VAT Act.',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011040',
    itemClsNm: 'Madeup fishing nets of manmade textile material of tariff number 56081100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011041',
    itemClsNm: 'Mosquito nets of tariff No 6304990110',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011043',
    itemClsNm:
      'Materials waste residues and by products whether or not in the form of pellets and preparations of a kind used in animal feeding of tariff numbers as listed in Part 1 of the first schedule of VAT Act 2013',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011044',
    itemClsNm: 'Unprocessed green tea',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011048',
    itemClsNm:
      'Inputs or raw materials supplied to solar equipment manufacturers for manufacture of solar equipment or deep cycle sealed batteries which exclusively use or store solar power ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011049',
    itemClsNm: 'Aircraft parts of heading 8803 excluding parts of goods of heading 8801',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011051',
    itemClsNm:
      'Taxable goods imported or purchased for direct and exclusive use in the implementation of official aid funded projects upon approval by the Cabinet Secretary responsible for the National Treasury',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011054',
    itemClsNm:
      'Goods imported or purchased locally for use by the local film producers and local filming agents upon recommendation by the Kenya Film Commission subject to approval by CS TNT',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011056',
    itemClsNm:
      'Inputs or raw materials locally purchased or imported by manufacturers of agricultural machinery and implements upon approval by the Cabinet Secretary responsible for industrialization',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011057',
    itemClsNm:
      'All goods including material supplies equipment machinery and motor vehicles for official use by the Kenya Defence Forces and the National Police Service',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011058',
    itemClsNm: 'Direction finding compasses instruments and appliances for aircraft',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011059',
    itemClsNm: 'Wheat seeds of tariff numbers 10011100 and 1001990100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011062',
    itemClsNm:
      'Taxable goods for direct and exclusive use for the construction of tourism facilities recreational parks of fifty acres or more convention and conference facilities upon recommendation by the Cabinet Secretary responsible for matters relating to recreational parks',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011063',
    itemClsNm:
      'Taxable goods equipment and apparatus for the direct and exclusive use for construction of specialized hospitals with a minimum bed capacity of fifty with accommodation facilities upon the recommendation by the Cabinet Secretary responsible for health who shall issue guidelines for the criteria to be used to determine eligibility for the exemption provided that notwithstanding this subparagraph any approval granted by the cabinet Secretary before the commencement thereof in respect of the supply of taxable goods and which is in supply of taxable goods and which is in force at such commencement shall continue to apply of the exempted taxable goods is made in full',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011066',
    itemClsNm:
      'Inputs or raw materials locally purchased or imported by manufacturers of clean cook stoves approved by the CS TNT upon recommendation by the CS responsible for energy',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011068',
    itemClsNm: 'Super absorbent polymer SAP of tariff number 39069000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011069',
    itemClsNm: 'Carrier tissue white 1 ply 14 point 5 GSM 47032100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011070',
    itemClsNm:
      'IP super soft fluff pulp for fluff 310 treated pulp 488 times 125 mm cellose of tariff number 47032100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011071',
    itemClsNm: 'Perforated PE film 15 to 22 gsm of tariff number 3990219000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011072',
    itemClsNm: 'Spunbound nonwoven 15 to 25 gsm of tariff number 56031100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011073',
    itemClsNm: 'Airlid paper with super absorbent polymer 180gsm67 of tariff number 48030000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011074',
    itemClsNm: 'Airlid paper with super absorbent polymer 80gsm67 of tariff number 48030000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011077',
    itemClsNm: 'Pressure sensitive adhesive of tariff number 3506990100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011078',
    itemClsNm: 'Plain polythene filmLPDE of tariff number 399021199010',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011079',
    itemClsNm: 'Plain polythene filmPE of tariff number 399021199010',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011080',
    itemClsNm: 'PE white 25 to 40 gsm release paper of tarrif number 48114900',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011081',
    itemClsNm: 'ADL 25 to 40 gsm of tariff number 56031100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011082',
    itemClsNm: 'Elasticized side tape of tariff number 54024400',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011083',
    itemClsNm:
      '12 to 16 gsm spunbound piyropo nonwoven coverstock 12gsm spunbound PP nonwoven SMS hydrophobic leg cuffs of tariff number 56031100',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011084',
    itemClsNm: 'Polymetric elastic 2 over 3 strands of tariff number 3990199010',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011089',
    itemClsNm:
      'Any other aircraft spare parts imported by aircraft operators or persons engaged in the business of aircraft maintenance upon recommendation by the competent authority responsible for civil aviation',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011090',
    itemClsNm:
      'Inputs for the manufacture of pesticides upon recommendation by the Cabinet Secretary for the time being responsible for matters relating to agriculture',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011091',
    itemClsNm:
      'Locally assembled motor vehicles for transportation of tourists purchased before clearance through Customs following conditions as specified in the VAT Act 2013 1st Schedule Section A no9901',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011095',
    itemClsNm:
      'The supply of natural water excluding bottled water by a NG, CGor any political subdivision thereof or a person approved by the CS responsible for water development for domestic or for industrial use',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011096',
    itemClsNm:
      'Articles of apparel clothing accessories and equipment specially designed for safety or protective purposes for use in registered hospitals and clinics or by CG or LA in firefighting',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011099',
    itemClsNm:
      'Goods imported by passengers arriving from places outside Kenya as specified in the VAT Act 2013 First Schedule section A no 99',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011100',
    itemClsNm: 'Exempt Goods (Paragraph 100 - 146)',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011101',
    itemClsNm:
      'Alcoholic or non alcoholic beverages supplied to the Kenya Defence Forces Canteen Organization',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011103',
    itemClsNm: 'Hearing aids excluding parts and accessories of tariff Number 90214000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011105',
    itemClsNm: 'Locally manufactured motherboards',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011106',
    itemClsNm:
      'Inputs for the manufacture of motherboards approved by the Cabinet Secretary responsible for information communication technology',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011107',
    itemClsNm:
      'Plant machinery and equipment used in the construction of a plastics recycling plant',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011108',
    itemClsNm:
      'The supply of maize corn flour cassava flour wheat or meslin flour and maize flour containing cassava flour by more than ten percent in weight provided that this paragra',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011109',
    itemClsNm:
      'Goods imported or purchased locally for the direct and exclusive use in the construction of houses under an affordable housing scheme approved by the CS on the recommendation of the CS responsible for matters relating to housing',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011110',
    itemClsNm:
      'Musical instruments and other musical equipment imported or purchased locally for exclusive use by educational institutions upon recommendation by the Cabinet Secretary responsible for Education',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011111',
    itemClsNm: 'Maize corn seeds of tariff no 10051000',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011112',
    itemClsNm:
      'Taxable good excl. motor vehicle imported or purchased for direct exclusive use in geothermal, oil, mining prospecting, exploration, product sharing as per the Energy Act 2019, Petroleum Act 2019, the Mining Act 2016 upon recommendation by the CS',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011113',
    itemClsNm:
      'Specialized equipment for the development and generation of solar and wind energy upon recommendation to the commissioner by the cabinet secretary responsible for matters relating to energy ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011114',
    itemClsNm:
      'Taxable goods supplied to persons that had a contract with the government prior to 25-04-2020 and contract provided for exemption from VAT provided that this exemption shall apply to the unexpired period of contract upon recommendation by CS Energy',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011115',
    itemClsNm:
      'Medical ventilators and the inputs for the manufacture of medical ventilators upon recommendation by the cabinet secretary responsible for matters relating to health ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011116',
    itemClsNm:
      'Physiotherapy accessories treadmills for cardiology therapy and treatment of tariff number 9506990100 for use by licensed hospitals upon approval by the cabinet secretary responsible for matters relating to health ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011117',
    itemClsNm:
      'Dexpanthenol of tariff number 33049900 used for medical nappy rash treatment by licensed hospitals upon approval by the cabinet secretary responsible for matters relating to health ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011118',
    itemClsNm:
      'Medicaments of tariff number 30034100 30034200 30034300 30034900 30036000 excluding goods of heading 3002 3005 or 3006 consisting of two or more constituents which have been mixed together for theraputic or prophylactic uses',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011119',
    itemClsNm:
      'Diagnostic or laboratory reagents of tariff number 38220000 on a backing prepared diagnostic or laboratory reagents whether or not on a backing other than those of heading 3002 or 3006 certified reference materials upon approval by CS Health ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011120',
    itemClsNm:
      'Electrodiagnostic apparatus of tariff numbers 90181100 90181200 90181300 90181400 90181900 90182000 90189000  upon approval by the cabinet secretary responsible for matters relating to health ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011121',
    itemClsNm:
      'Other instruments and appliances of tariff number 90184100 used in dental sciences dental drill engines whether or not combined on a single base with other dental equipment  upon approval by CS Health',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011122',
    itemClsNm:
      'Other instruments and appliances including surgical blades of tariff number 90184900 90185000 90189000 used in dental sciences dental drill engines whether or not combined on a single base with other dental equipment upon approval by CS Health',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011123',
    itemClsNm:
      'Ozone therapy Oxygen therapy aerosol therapy artificial respiration or other theraputic respiration apapratus upon approval by CS Health ',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011124',
    itemClsNm:
      'Other breathing appliances and gas masks excluding protective masks having neither mechanical parts nor replaceable filters upon approval by CS Health',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011125',
    itemClsNm:
      'Artificial teeth and dental fittings of tariff number 90212100 90212900 and artificial parts of the body of tariff number 90213100 90213900 90215000 and 90219000 upon approval by CS Health',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011126',
    itemClsNm:
      'Apparatus based on the use of x rays whether or not for medical surgical or dental of tariff numbers 90221200 90221300 90221400 and 90221900 upon approval by CS Health',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011127',
    itemClsNm:
      'Apparatus based on the use of alpha beta or gamma radiations whether or not medical surgical or dental of tariff numbers 90222100 90222900 90223000 and 90229000 upon approval by CS Health',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '99011128',
    itemClsNm:
      'Discs tape solid-state nonvolatile storage devices smart cards and other media for the recording of sound or of other phenomena whether or not recorded of tariff number 85238010 but excluding products of chapter 37 software upon approval by CS Health',
    itemClsLvl: 4,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '80161500',
    itemClsNm: 'Management support services',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '11161500',
    itemClsNm: 'Silk fabrics',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '31161500',
    itemClsNm: 'Screws',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '21101600',
    itemClsNm: 'Agricultural machinery for planting and seeding',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '51101600',
    itemClsNm: 'Amebicides and Trichomonacides and Antiprotozoals',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '81101600',
    itemClsNm: 'Mechanical engineering',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '22101600',
    itemClsNm: 'Paving equipment',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '32101600',
    itemClsNm: 'Integrated circuits',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '82101600',
    itemClsNm: 'Broadcast advertising',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '92101600',
    itemClsNm: 'Fire services',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '13101600',
    itemClsNm: 'Processed and synthetic rubber',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '53101600',
    itemClsNm: 'Shirts and blouses',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '73101600',
    itemClsNm: 'Chemicals and fertilizers production',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '83101600',
    itemClsNm: 'Oil and gas utilities',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '93101600',
    itemClsNm: 'Political officials',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '24101600',
    itemClsNm: 'Lifting equipment and accessories',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '44101600',
    itemClsNm: 'Paper processing machines and accessories',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '84101600',
    itemClsNm: 'Aid financing',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '15101600',
    itemClsNm: 'Solid and gel fuels',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '25101600',
    itemClsNm: 'Product and material transport vehicles',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '45101600',
    itemClsNm: 'Printing machinery accessories',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '85101600',
    itemClsNm: 'Healthcare provider support persons',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '95101600',
    itemClsNm: 'Commercial land parcels',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '46101600',
    itemClsNm: 'Ammunition',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '56101600',
    itemClsNm: 'Outdoor furniture',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '76101600',
    itemClsNm: 'Hazardous material decontamination',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '86101600',
    itemClsNm: 'Scientific vocational training services',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '47101600',
    itemClsNm: 'Water treatment consumables',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '77101600',
    itemClsNm: 'Environmental planning',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '48101600',
    itemClsNm: 'Food preparation equipment',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
  {
    itemClsCd: '78101600',
    itemClsNm: 'Rail cargo transport',
    itemClsLvl: 3,
    taxTyCd: null,
    mjrTgYn: null,
    useYn: 'Y',
  },
];
