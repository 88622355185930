function sanitizeData(data) {
  return Object.keys(data).reduce((sanitized, key) => {
    const value = data[key];
    if (value !== '' && value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        sanitized[key] = value.map((item) =>
          typeof item === 'object' ? sanitizeData(item) : item
        );
      } else if (typeof value === 'object') {
        sanitized[key] = sanitizeData(value);
      } else {
        sanitized[key] = value;
      }
    }
    return sanitized;
  }, {});
}

export default sanitizeData;
