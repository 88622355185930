// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  POS: '/pos',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  pos: ROOTS.POS,
  dashboard: {
    root: ROOTS.DASHBOARD,
    sales: {
      root: `${ROOTS.DASHBOARD}/sales`,
      newSale: `${ROOTS.DASHBOARD}/sales/new-sale`,
      salesList: `${ROOTS.DASHBOARD}/sales/sales-list`,
      saleDetails: (id) => `${ROOTS.DASHBOARD}/sales/${id}`,
    },
    items: {
      root: `${ROOTS.DASHBOARD}/items`,
      newProduct: `${ROOTS.DASHBOARD}/items/new-item`,
      productList: `${ROOTS.DASHBOARD}/items/item-list`,
      productDetails: (id) => `${ROOTS.DASHBOARD}/items/${id}`,
      editProduct: (id) => `${ROOTS.DASHBOARD}/items/edit-item/${id}`,
      // item composition
      newItemComposition: `${ROOTS.DASHBOARD}/items/new-item-composition`,
      itemCompositionList: `${ROOTS.DASHBOARD}/items/item-composition-list`,
      itemCompositionDetails: (id) => `${ROOTS.DASHBOARD}/items/${id}`,
      editItemComposition: (id) => `${ROOTS.DASHBOARD}/items/edit-item-composition/${id}`,
    },
    insurance: {
      root: `${ROOTS.DASHBOARD}/insurance`,
      newInsurance: `${ROOTS.DASHBOARD}/insurance/new-insurance`,
      insuranceList: `${ROOTS.DASHBOARD}/insurance/insurance-list`,
      insuranceDetails: (id) => `${ROOTS.DASHBOARD}/insurance/${id}`,
      editInsurance: (id) => `${ROOTS.DASHBOARD}/insurance/edit-insurance/${id}`,
    },
    purchase: {
      root: `${ROOTS.DASHBOARD}/purchase`,
      newPurchase: `${ROOTS.DASHBOARD}/purchase/new-purchase`,
      purchaseList: `${ROOTS.DASHBOARD}/purchase/purchase-list`,
      purchaseDetails: (id) => `${ROOTS.DASHBOARD}/purchase/${id}`,
    },
    stock: {
      root: `${ROOTS.DASHBOARD}/stock`,
      newStock: `${ROOTS.DASHBOARD}/stock/new-stock`,
      stockList: `${ROOTS.DASHBOARD}/stock/stock-list`,
      stockDetails: (id) => `${ROOTS.DASHBOARD}/stock/${id}`,
      stockItemList: `${ROOTS.DASHBOARD}/stock/stock-item-list`,
      stockItemDetails: (id) => `${ROOTS.DASHBOARD}/stock/stock-item/${id}`,
    },
    report: {
      root: `${ROOTS.DASHBOARD}/report`,
      newReport: `${ROOTS.DASHBOARD}/report/new-report`,
      reportList: `${ROOTS.DASHBOARD}/report/report-list`,
    },
    people: {
      root: `${ROOTS.DASHBOARD}/people`,
      newCustomer: `${ROOTS.DASHBOARD}/people/new-customer`,
      editCustomer: (id) => `${ROOTS.DASHBOARD}/people/edit-customer/${id}`,
      customerList: `${ROOTS.DASHBOARD}/people/customer-list`,
      newSupplier: `${ROOTS.DASHBOARD}/people/new-supplier`,
      editSupplier: (id) => `${ROOTS.DASHBOARD}/people/edit-supplier/${id}`,
      supplierList: `${ROOTS.DASHBOARD}/people/supplier-list`,
      newUser: `${ROOTS.DASHBOARD}/people/new-user`,
      editUser: (id) => `${ROOTS.DASHBOARD}/people/edit-user/${id}`,
      userList: `${ROOTS.DASHBOARD}/people/user-list`,
    },
    settings: {
      root: `${ROOTS.DASHBOARD}/settings`,
      company: `${ROOTS.DASHBOARD}/settings/company`,
      profile: `${ROOTS.DASHBOARD}/settings/profile`,
      billing: `${ROOTS.DASHBOARD}/settings/billing`,
      integration: `${ROOTS.DASHBOARD}/settings/integration`,
      notification: `${ROOTS.DASHBOARD}/settings/notification`,
      security: `${ROOTS.DASHBOARD}/settings/security`,
      help: `${ROOTS.DASHBOARD}/settings/help`,
    },
  },
};
