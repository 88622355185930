import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  insurances: [],
  activeStep: 0,
  insurance: null,
  insurancesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  insuranceStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    // GET INSURANCES
    getInsurancesStart(state) {
      state.insurancesStatus.loading = true;
      state.insurancesStatus.empty = false;
      state.insurancesStatus.error = null;
    },
    getInsurancesFailure(state, action) {
      state.insurancesStatus.loading = false;
      state.insurancesStatus.empty = false;
      state.insurancesStatus.error = action.payload;
    },
    getInsurancesSuccess(state, action) {
      const insurances = action.payload;
      state.insurances = insurances;
      state.insurancesStatus.loading = false;
      state.insurancesStatus.empty = !insurances.length;
      state.insurancesStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // GET INSURANCE
    getInsuranceStart(state) {
      state.insuranceStatus.loading = true;
      state.insuranceStatus.error = null;
    },
    getInsuranceFailure(state, action) {
      state.insuranceStatus.loading = false;
      state.insuranceStatus.error = action.payload;
    },
    getInsuranceSuccess(state, action) {
      state.insurance = action.payload;
      state.insuranceStatus.loading = false;
      state.insuranceStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getInsurancesStart,
  getInsurancesFailure,
  getInsurancesSuccess,
  backStep,
  nextStep,
  gotoStep,
  getInsuranceStart,
  getInsuranceFailure,
  getInsuranceSuccess,
} = slice.actions;

export function getInsurances(params) {
  return async (dispatch) => {
    dispatch(getInsurancesStart());
    try {
      const response = await axios.get(API_ENDPOINTS.insurance.insurances, { params });
      console.log(response.data);
      dispatch(getInsurancesSuccess(response.data.results));
      return response.data;
    } catch (error) {
      dispatch(getInsurancesFailure(error.message));
      return null;
    }
  };
}

export function getInsurance(insuranceId) {
  return async (dispatch) => {
    dispatch(getInsuranceStart());
    try {
      const response = await axios.get(API_ENDPOINTS.insurance.insurance(insuranceId));
      dispatch(getInsuranceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getInsuranceFailure(error));
      return null;
    }
  };
}

export function createInsurance(insurance) {
  return async (dispatch) => {
    dispatch(getInsuranceStart());
    try {
      const response = await axios.post(API_ENDPOINTS.insurance.create, insurance);
      dispatch(getInsuranceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getInsuranceFailure(error));
      return null;
    }
  };
}

export function updateInsurance(insurance) {
  return async (dispatch) => {
    dispatch(getInsuranceStart());
    try {
      const response = await axios.put(API_ENDPOINTS.insurance.update(insurance.id), insurance);
      dispatch(getInsuranceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getInsuranceFailure(error));
      return null;
    }
  };
}

export function deleteInsurance(insuranceId) {
  return async (dispatch) => {
    dispatch(getInsuranceStart());
    try {
      const response = await axios.delete(API_ENDPOINTS.insurance.delete(insuranceId));
      dispatch(getInsuranceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getInsuranceFailure(error));
      return null;
    }
  };
}
