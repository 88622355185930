import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  customers: [],
  activeStep: 0,
  customer: null,
  customersStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  customerStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // GET CUSTOMERS
    getCustomersStart(state) {
      state.customersStatus.loading = true;
      state.customersStatus.empty = false;
      state.customersStatus.error = null;
    },
    getCustomersFailure(state, action) {
      state.customersStatus.loading = false;
      state.customersStatus.empty = false;
      state.customersStatus.error = action.payload;
    },
    getCustomersSuccess(state, action) {
      const customers = action.payload;
      state.customers = customers;
      state.customersStatus.loading = false;
      state.customersStatus.empty = !customers.length;
      state.customersStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // GET CUSTOMER
    getCustomerStart(state) {
      state.customerStatus.loading = true;
      state.customerStatus.error = null;
    },
    getCustomerFailure(state, action) {
      state.customerStatus.loading = false;
      state.customerStatus.error = action.payload;
    },
    getCustomerSuccess(state, action) {
      state.customer = action.payload;
      state.customerStatus.loading = false;
      state.customerStatus.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCustomersStart,
  getCustomersFailure,
  getCustomersSuccess,
  backStep,
  nextStep,
  gotoStep,
  getCustomerStart,
  getCustomerFailure,
  getCustomerSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCustomers() {
  return async (dispatch) => {
    dispatch(getCustomersStart());
    try {
      const response = await axios.get(API_ENDPOINTS.customer.list);
      console.log('GET CUSTOMERS', response.data.results);
      dispatch(getCustomersSuccess(response.data.results));
    } catch (error) {
      dispatch(getCustomersFailure(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomer(customerId) {
  return async (dispatch) => {
    dispatch(getCustomerStart());
    try {
      const response = await axios.get(API_ENDPOINTS.customer.list, {
        params: { id: customerId },
      });
      dispatch(getCustomerSuccess(response.data));
    } catch (error) {
      dispatch(getCustomerFailure(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function createCustomer(customer) {
  return async (dispatch) => {
    dispatch(getCustomerStart());
    try {
      const response = await axios.post(API_ENDPOINTS.customer.post, customer);
      console.log('CREATE CUSTOMER', response.data);
      dispatch(slice.actions.getCustomerSuccess(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getCustomerFailure(error.message));
      return error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateCustomer(id, customer) {
  return async (dispatch) => {
    dispatch(getCustomerStart());
    try {
      const response = await axios.put(API_ENDPOINTS.customer.put(id), customer);
      console.log('UPDATE CUSTOMER', response.data);
      dispatch(slice.actions.getCustomerSuccess(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.getCustomerFailure(error.message));
      return error;
    }
  };
}
