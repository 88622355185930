import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import product from './slices/product';
import customer from './slices/customer';
import user from './slices/user';
import sale from './slices/sale';
import summary from './slices/summary';
import items_class from './slices/item_class';
import code_details from './slices/code_details';
import profile from './slices/profile';
import authentication from './slices/authentication';
import purchase from './slices/purchase';
import save_stock_master from './slices/save-stock-master';
import stock from './slices/stock';
import branches from './slices/branches';
import integration from './slices/integration';
import notices from './slices/notices';
import import_items from './slices/import_items';
import item_composition from './slices/item_composition';
import insurance from './slices/insurance';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['product'],
};

const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['customers', 'customer'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['users', 'user'],
};

const salePersistConfig = {
  key: 'sale',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sales', 'sale', 'checkout', 'heldOrders'],
};

const sammaryPersistConfig = {
  key: 'summary',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['customers', 'insurances', 'products', 'sales', 'users'],
};

const itemsclassPersistConfig = {
  key: 'items_class',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['items_class'],
};

const codeDetailsPersistConfig = {
  key: 'code_details',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['code_details'],
};

const profilePersistConfig = {
  key: 'profile',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['profile'],
};

const authenticationPersistConfig = {
  key: 'authentication',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['loading', 'error'],
};

const purchasePersistConfig = {
  key: 'purchase',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['purchases', 'purchase'],
};

const saveStockMasterPersistConfig = {
  key: 'save_stock_master',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['save_stock_master'],
};

const storePersistConfig = {
  key: 'stocks_movement',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['stocks_movement'],
};

const branchesPersistConfig = {
  key: 'branches',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['branches', 'branch'],
};

const integrationPersistConfig = {
  key: 'integration',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['integrations', 'integration'],
};

const noticesPersistConfig = {
  key: 'notices',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['notices'],
};

const importItemsPersistConfig = {
  key: 'import_items',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['importItems', 'importItemsStatus'],
};

const itemCompositionPersistConfig = {
  key: 'item_composition',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['item_compositions', 'item_composition', 'item_compositionStatus'],
};

const insurancePersistConfig = {
  key: 'insurance',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['insurances', 'insurance', 'insuranceStatus'],
};

// ----------------------------------------------------------------------

export const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, product),
  customer: persistReducer(customerPersistConfig, customer),
  user: persistReducer(userPersistConfig, user),
  sale: persistReducer(salePersistConfig, sale),
  summary: persistReducer(sammaryPersistConfig, summary),
  items_class: persistReducer(itemsclassPersistConfig, items_class),
  code_details: persistReducer(codeDetailsPersistConfig, code_details),
  profile: persistReducer(profilePersistConfig, profile),
  authentication: persistReducer(authenticationPersistConfig, authentication),
  purchase: persistReducer(purchasePersistConfig, purchase),
  save_stock_master: persistReducer(saveStockMasterPersistConfig, save_stock_master),
  stocks_movement: persistReducer(storePersistConfig, stock),
  branches: persistReducer(branchesPersistConfig, branches),
  integration: persistReducer(integrationPersistConfig, integration),
  notices: persistReducer(noticesPersistConfig, notices),
  import_items: persistReducer(importItemsPersistConfig, import_items),
  item_composition: persistReducer(itemCompositionPersistConfig, item_composition),
  insurance: persistReducer(insurancePersistConfig, insurance),
});
