import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import sanitizeData from 'src/utils/sanitize-data';
// ----------------------------------------------------------------------

const initialState = {
  itemCompositions: [],
  itemComposition: null,
  activeStep: 0,
  page: 0,
  rowsPerPage: 5,
  totalItems: 0,
  defaultOrderBy: 'id',
  orderDirection: 'desc',
  itemCompositionsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  itemCompositionStatus: {
    loading: false,
    error: null,
  },
  filters: {
    searchQuery: '',
    category: 'all',
    // Add more filters as needed
  },
};

const slice = createSlice({
  name: 'itemComposition',
  initialState,
  reducers: {
    // GET ITEM COMPOSITIONS
    getItemCompositionsStart(state) {
      state.itemCompositionsStatus.loading = true;
      state.itemCompositionsStatus.empty = false;
      state.itemCompositionsStatus.error = null;
    },
    getItemCompositionsFailure(state, action) {
      state.itemCompositionsStatus.loading = false;
      state.itemCompositionsStatus.empty = false;
      state.itemCompositionsStatus.error = action.payload;
    },
    getItemCompositionsSuccess(state, action) {
      const { itemCompositions, totalItems } = action.payload;
      state.itemCompositions = itemCompositions;
      state.totalItems = totalItems;
      state.itemCompositionsStatus.loading = false;
      state.itemCompositionsStatus.empty = !itemCompositions.length;
      state.itemCompositionsStatus.error = null;
    },

    // GET ITEM COMPOSITION
    getItemCompositionStart(state) {
      state.itemCompositionStatus.loading = true;
      state.itemCompositionStatus.error = null;
    },
    getItemCompositionFailure(state, action) {
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = action.payload;
    },
    getItemCompositionSuccess(state, action) {
      state.itemComposition = action.payload;
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = null;
    },

    backStep(state) {
      state.activeStep -= 1;
    },

    nextStep(state) {
      state.activeStep += 1;
    },

    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // CREATE ITEM COMPOSITION
    createItemCompositionStart(state) {
      state.itemCompositionStatus.loading = true;
      state.itemCompositionStatus.error = null;
    },
    createItemCompositionFailure(state, action) {
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = action.payload;
    },
    createItemCompositionSuccess(state, action) {
      state.itemComposition = action.payload;
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = null;
    },

    // UPDATE ITEM COMPOSITION
    updateItemCompositionStart(state) {
      state.itemCompositionStatus.loading = true;
      state.itemCompositionStatus.error = null;
    },
    updateItemCompositionFailure(state, action) {
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = action.payload;
    },
    updateItemCompositionSuccess(state, action) {
      state.itemComposition = action.payload;
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = null;
    },

    // DELETE ITEM COMPOSITION
    deleteItemCompositionStart(state) {
      state.itemCompositionStatus.loading = true;
      state.itemCompositionStatus.error = null;
    },

    deleteItemCompositionFailure(state, action) {
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = action.payload;
    },

    deleteItemCompositionSuccess(state) {
      state.itemComposition = null;
      state.itemCompositionStatus.loading = false;
      state.itemCompositionStatus.error = null;
    },

    // SET PAGE
    setPage(state, action) {
      state.page = action.payload;
    },

    // SET ROWS PER PAGE
    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },

    // SET FILTERS
    setFilters(state, action) {
      state.filters = action.payload;
    },

    // SET DEFAULT ORDER BY
    setDefaultOrderBy(state, action) {
      state.defaultOrderBy = action.payload;
    },

    // SET ORDER DIRECTION
    setOrderDirection(state, action) {
      state.orderDirection = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getItemCompositionsStart,
  getItemCompositionsFailure,
  getItemCompositionsSuccess,
  getItemCompositionStart,
  getItemCompositionFailure,
  getItemCompositionSuccess,
  backStep,
  nextStep,
  gotoStep,
  setPage,
  setRowsPerPage,
  setFilters,
  setDefaultOrderBy,
  setOrderDirection,
  createItemCompositionStart,
  createItemCompositionFailure,
  createItemCompositionSuccess,
  updateItemCompositionStart,
  updateItemCompositionFailure,
  updateItemCompositionSuccess,
  deleteItemCompositionStart,
  deleteItemCompositionFailure,
  deleteItemCompositionSuccess,
} = slice.actions;

export const getItemCompositions =
  (page = 0, rowsPerPage = 5, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch(getItemCompositionsStart());
      const response = await axios.get(API_ENDPOINTS.item_compositions.list, {
        params: { page, rowsPerPage, ...filters },
      });
      console.log('GET ITEM COMPOSITIONS RESPONSE', response.data);

      const { results, count } = response.data;

      dispatch(
        slice.actions.getItemCompositionsSuccess({
          itemCompositions: results,
          totalItems: count,
        })
      );
    } catch (error) {
      dispatch(getItemCompositionsFailure(error));
    }
  };

export const getItemComposition = (id) => async (dispatch) => {
  try {
    dispatch(getItemCompositionStart());
    const response = await axios.get(API_ENDPOINTS.item_compositions.details(id));
    dispatch(getItemCompositionSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(getItemCompositionFailure(error));
    return null;
  }
};

export const createItemComposition = (itemComposition) => async (dispatch) => {
  try {
    dispatch(createItemCompositionStart());
    const response = await axios.post(API_ENDPOINTS.item_compositions.post, itemComposition);
    dispatch(createItemCompositionSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(createItemCompositionFailure(error));
    return null;
  }
};

export const updateItemComposition = (id, itemComposition) => async (dispatch) => {
  try {
    dispatch(updateItemCompositionStart());
    const response = await axios.put(API_ENDPOINTS.item_compositions.update(id), itemComposition);
    dispatch(updateItemCompositionSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(updateItemCompositionFailure(error));
    return null;
  }
};

export const deleteItemComposition = (id) => async (dispatch) => {
  try {
    dispatch(deleteItemCompositionStart());
    await axios.delete(API_ENDPOINTS.item_compositions.delete(id));
    dispatch(deleteItemCompositionSuccess());
  } catch (error) {
    dispatch(deleteItemCompositionFailure(error));
  }
};
