import { createSlice } from '@reduxjs/toolkit';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState = {
  importItems: [],
  importItemsStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'import_items',
  initialState,
  reducers: {
    // GET IMPORT ITEMS
    getImportItemsStart(state) {
      state.importItemsStatus.loading = true;
      state.importItemsStatus.empty = false;
      state.importItemsStatus.error = null;
    },
    getImportItemsFailure(state, action) {
      state.importItemsStatus.loading = false;
      state.importItemsStatus.empty = false;
      state.importItemsStatus.error = action.payload;
    },
    getImportItemsSuccess(state, action) {
      const importItems = action.payload;
      state.importItems = importItems;
      state.importItemsStatus.loading = false;
      state.importItemsStatus.empty = !importItems.length;
      state.importItemsStatus.error = null;
    },
  },
});

export default slice.reducer;
export const { getImportItemsStart, getImportItemsFailure, getImportItemsSuccess } = slice.actions;

// Thunk for fetching import items list (if needed)
export function getImportItems(options = {}) {
  return async (dispatch) => {
    dispatch(getImportItemsStart());
    try {
      const response = await axios.get(API_ENDPOINTS.import_items.list, {
        params: {
          limit: 200,
          offset: 0,
          ...options,
        },
      });
      console.log('IMPORT ITEMS:', response.data.results);

      dispatch(getImportItemsSuccess(response.data.results));
    } catch (error) {
      dispatch(getImportItemsFailure(error));
    }
  };
}

// Thunk for synchronizing import items
export function syncImportItems({ data = { lastReqDt: '20200101000000' } } = {}) {
  return async () => {
    try {
      console.log('Starting import items synchronization...');
      const response = await axios.post(API_ENDPOINTS.import_items.sync, data);
      console.log('Import items sync successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to sync import items:', error);
      return error;
    }
  };
}
