import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    // LOGIN
    loginStart(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.authUser = action.payload;
      state.loading = false;
      state.error = null;
    },
    loginFailure(state, action) {
      state.authUser = null;
      state.loading = false;
      state.error = action.payload;
    },

    // LOGOUT
    logoutStart(state) {
      state.loading = true;
      state.error = null;
    },
    logoutSuccess(state) {
      state.authUser = null;
      state.loading = false;
      state.error = null;
    },
    logoutFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // REGISTER
    registerStart(state) {
      state.loading = true;
      state.error = null;
    },
    registerSuccess(state, action) {
      state.authUser = action.payload;
      state.loading = false;
      state.error = null;
    },
    registerFailure(state, action) {
      state.authUser = null;
      state.loading = false;
      state.error = action.payload;
    },

    // CHANGE PASSWORD
    changePasswordStart(state) {
      state.loading = true;
      state.error = null;
    },
    changePasswordSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    changePasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // FORGOT PASSWORD
    forgotPasswordStart(state) {
      state.loading = true;
      state.error = null;
    },
    forgotPasswordSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    forgotPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // RESET PASSWORD
    resetPasswordStart(state) {
      state.loading = true;
      state.error = null;
    },
    resetPasswordSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    resetPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutStart,
  logoutSuccess,
  logoutFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
} = slice.actions;

export function changePassword(data) {
  return async (dispatch) => {
    dispatch(changePasswordStart());
    try {
      const response = await axios.post(API_ENDPOINTS.auth.changePassword, data);
      console.log('RESPONSE', response);
      if (response && response.data && response.data.success) {
        dispatch(changePasswordFailure(response.data ? response.data.message : 'Unknown error'));
        return response.data;
      }
      dispatch(changePasswordSuccess(response.data.message));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(changePasswordFailure(error.message));
      return error;
    }
  };
}

export function forgotPassword(data) {
  return async (dispatch) => {
    dispatch(forgotPasswordStart());
    try {
      const response = await axios.post(API_ENDPOINTS.auth.forgotPassword, data);
      console.log('RESPONSE', response);
      if (response.success) {
        dispatch(forgotPasswordSuccess(response.message));
      } else {
        dispatch(forgotPasswordFailure(response.message));
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export function resetPassword(data) {
  return async (dispatch) => {
    dispatch(resetPasswordStart());
    try {
      const response = await axios.post(API_ENDPOINTS.auth.resetPassword, data);
      console.log('RESPONSE', response);
      if (response.success) {
        dispatch(resetPasswordSuccess(response.message));
      } else {
        dispatch(resetPasswordFailure(response.message));
      }
    } catch (error) {
      console.error(error);
    }
  }
}

