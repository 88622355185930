import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState = {
  stocks_movement: [],
  stock_movement: null,
  stock_items: [],
  stock_item: null,
  activeStep: 0,
  page: 0, // Pagination page
  rowsPerPage: 10, // Default rows per page
  totalItems: 0, // Total items available
  stocksStatus: {
    loading: false,
    empty: false,
    error: null,
  },
  stockStatus: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'stocks_movement',
  initialState,
  reducers: {
    // GET STOCK MOVEMENT LIST
    getStockStart(state) {
      state.stocksStatus.loading = true;
      state.stocksStatus.error = null;
    },
    getStockSuccess(state, action) {
      const { data, totalItems } = action.payload;
      state.stocksStatus.loading = false;
      state.stocksStatus.empty = data.length === 0;
      state.stocks_movement = data;
      state.totalItems = totalItems;
    },
    getStockFailure(state, action) {
      state.stocksStatus.loading = false;
      state.stocksStatus.error = action.payload;
    },

    // GET SINGLE STOCK MOVEMENT
    getSingleStockStart(state) {
      state.stockStatus.loading = true;
      state.stockStatus.error = null;
    },
    getSingleStockSuccess(state, action) {
      state.stockStatus.loading = false;
      state.stock_movement = action.payload;
    },
    getSingleStockFailure(state, action) {
      state.stockStatus.loading = false;
      state.stockStatus.error = action.payload;
    },

    // STOCK ITEMS
    getStockItemsStart(state) {
      state.stocksStatus.loading = true;
      state.stocksStatus.error = null;
    },
    getStockItemsSuccess(state, action) {
      const { data, totalItems } = action.payload;
      state.stocksStatus.loading = false;
      state.stocksStatus.empty = data.length === 0;
      state.stock_items = data;
      state.totalItems = totalItems;
    },
    getStockItemsFailure(state, action) {
      state.stocksStatus.loading = false;
      state.stocksStatus.error = action.payload;
    },

    // SINGLE STOCK ITEM
    getSingleStockItemStart(state) {
      state.stockStatus.loading = true;
      state.stockStatus.error = null;
    },
    getSingleStockItemSuccess(state, action) {
      state.stockStatus.loading = false;
      state.stock_item = action.payload;
    },
    getSingleStockItemFailure(state, action) {
      state.stockStatus.loading = false;
      state.stockStatus.error = action.payload;
    },

    // SET ACTIVE STEP
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },

    nextStep(state) {
      state.activeStep += 1;
    },
    backStep(state) {
      state.activeStep -= 1;
    },
    gotoStep(state, action) {
      state.activeStep = action.payload;
    },

    // PAGINATION HANDLERS
    setPage(state, action) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  getStockStart,
  getStockSuccess,
  getStockFailure,
  getSingleStockStart,
  getSingleStockSuccess,
  getSingleStockFailure,
  getStockItemsStart,
  getStockItemsSuccess,
  getStockItemsFailure,
  getSingleStockItemStart,
  getSingleStockItemSuccess,
  getSingleStockItemFailure,
  setActiveStep,
  nextStep,
  backStep,
  gotoStep,
  setPage,
  setRowsPerPage,
} = slice.actions;

export function getStocksMovement(options = {}) {
  return async (dispatch, getState) => {
    const { page, rowsPerPage } = getState().stocks_movement; // Corrected path
    dispatch(slice.actions.getStockStart());

    try {
      const response = await axios.get(API_ENDPOINTS.select_stock_items.list, {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering: '-id',
          ...options,
        },
      });

      const { results, count } = response.data;

      console.log('STOCKS MOVE', response.data);

      dispatch(
        slice.actions.getStockSuccess({
          data: results,
          totalItems: count,
        })
      );
    } catch (error) {
      dispatch(slice.actions.getStockFailure(error.message || 'Unable to get stock movements.'));
    }
  };
}

export const getSingleStockMovement = (id) => async (dispatch) => {
  dispatch(getSingleStockStart());

  try {
    const response = await axios.get(`${API_ENDPOINTS.select_stock_items.details(id)}`);
    console.log('STOCKS MOVE:', id, response.data);

    dispatch(getSingleStockSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || error.message || 'Unable to get stock movement.';
    console.error('Error fetching stock movement:', errorMessage);

    dispatch(getSingleStockFailure(errorMessage));
  }
};

export function getStockItems(options = {}) {
  return async (dispatch, getState) => {
    const { page, rowsPerPage } = getState().stocks_movement;
    dispatch(getStockItemsStart());

    try {
      const response = await axios.get(API_ENDPOINTS.save_stock_items.list, {
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          ordering: '-id',
          ...options,
        },
      });

      const { results, count } = response.data;

      dispatch(
        getStockItemsSuccess({
          data: results,
          totalItems: count,
        })
      );
    } catch (error) {
      dispatch(getStockItemsFailure(error.message || 'Unable to get stock items.'));
    }
  };
}

export const getSingleStockItem = (id) => async (dispatch) => {
  dispatch(getSingleStockItemStart());

  try {
    const response = await axios.get(`${API_ENDPOINTS.save_stock_items.details(id)}`);
    dispatch(getSingleStockItemSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || error.message || 'Unable to get stock item.';
    dispatch(getSingleStockItemFailure(errorMessage));
  }
};

export function createStock(data) {
  return async (dispatch) => {
    dispatch(getStockItemsStart());
    try {
      const response = await axios.post(API_ENDPOINTS.save_stock_items.post, data);
      console.log('CREATE STOCK ITEM: response', response.data);
      dispatch(getStockItems());
      return response.data;
    } catch (error) {
      dispatch(getStockItemsFailure(error.message));
      return error;
    }
  };
}

export function syncStockMovementFromEtims({ data = { lastReqDt: '20200101000000' } }) {
  return async () => {
    try {
      console.log('SYNC STOCK MOVEMENT FROM ETIMS');
      const response = await axios.post(API_ENDPOINTS.select_stock_items.sync, data);
      console.log('SYNC STOCK MOVEMENT RESPONSE', response.data);
      return response.data;
    } catch (error) {
      console.error('Error syncing stock movement:', error);
      return error;
    }
  };
}
