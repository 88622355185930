import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState = {
  saveStockMaster: [],
  totalItems: 0, // Track total items available
  saveStockMasterStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'save_stock_master',
  initialState,
  reducers: {
    // GET SAVE STOCK MASTER
    getSaveStockMasterStart(state) {
      state.saveStockMasterStatus.loading = true;
      state.saveStockMasterStatus.empty = false;
      state.saveStockMasterStatus.error = null;
    },
    getSaveStockMasterFailure(state, action) {
      state.saveStockMasterStatus.loading = false;
      state.saveStockMasterStatus.empty = false;
      state.saveStockMasterStatus.error = action.payload;
    },
    getSaveStockMasterSuccess(state, action) {
      const { saveStockMaster, totalItems } = action.payload;
      state.saveStockMaster = saveStockMaster;
      state.totalItems = totalItems;
      state.saveStockMasterStatus.loading = false;
      state.saveStockMasterStatus.empty = !saveStockMaster.length;
      state.saveStockMasterStatus.error = null;
    },

    // POST SAVE STOCK MASTER
    postSaveStockMasterStart(state) {
      state.saveStockMasterStatus.loading = true;
      state.saveStockMasterStatus.error = null;
    },
    postSaveStockMasterFailure(state, action) {
      state.saveStockMasterStatus.loading = false;
      state.saveStockMasterStatus.error = action.payload;
    },
    postSaveStockMasterSuccess(state, action) {
      state.saveStockMaster = action.payload;
      state.saveStockMaster.loading = false;
      state.saveStockMaster.error = null;
    },
  },
});

export default slice.reducer;

export const {
  getSaveStockMasterStart,
  getSaveStockMasterFailure,
  getSaveStockMasterSuccess,

  postSaveStockMasterStart,
  postSaveStockMasterFailure,
  postSaveStockMasterSuccess,
} = slice.actions;

export function getSaveStockMaster(options = {}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.getSaveStockMasterStart());

    try {
      const response = await axios.get(API_ENDPOINTS.save_stock_master.list, {
        params: {
          ...options,
          ordering: '-id',
        },
      });

      console.log('GET SAVE STOCK MASTER RESPONSE', response.data);

      // Assuming response.data contains total items in 'count'
      const { results, count } = response.data;

      dispatch(
        slice.actions.getSaveStockMasterSuccess({
          saveStockMaster: results,
          totalItems: count,
        })
      );
    } catch (error) {
      dispatch(slice.actions.getSaveStockMasterFailure(error.message || 'Error fetching products'));
    }
  };
}

export function postSaveStockMaster(data) {
  return async (dispatch) => {
    dispatch(slice.actions.postSaveStockMasterStart());
    try {
      const response = await axios.post(API_ENDPOINTS.save_stock_master.post, data);
      console.log('POST SAVE STOCK MASTER', response.data);
      dispatch(slice.actions.postSaveStockMasterSuccess(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.postSaveStockMasterFailure(error));
      return error;
    }
  };
}
