import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  auth: {
    me: '/api/authentication/me/',
    register: '/api/authentication/register/',
    login: '/api/authentication/login/',
    logout: '/api/authentication/logout/',
    changePassword: '/api/authentication/change-password/',
    forgotPassword: '/api/authentication/forgot-password/',
    resetPassword: '/api/authentication/reset-password/',
  },
  profile: {
    me: '/api/authentication/me/',
  },
  summary: {
    customers: '/api/summary/customers/',
    insurances: '/api/summary/insurances/',
    products: '/api/summary/products/',
    sales: '/api/summary/sales/',
    users: '/api/summary/users/',
  },
  item_compositions: {
    list: '/api/vscuProductManagement/save-item-composition/',
    post: '/api/vscuProductManagement/save-item-composition/',
    update: (id) => `/api/vscuProductManagement/save-item-composition/${id}/`,
    details: (id) => `/api/vscuProductManagement/save-item-composition/${id}/`,
    delete: (id) => `/api/vscuProductManagement/save-item-composition/${id}/`,
  },
  product: {
    list: '/api/vscuProductManagement/select-items/',
    details: (id) => `api/vscuProductManagement/select-items/${id}/`,
    sync: '/api/vscuProductManagement/select-items/',
    //
    post: '/api/vscuProductManagement/save-item/',
    update: (id) => `/api/vscuProductManagement/save-item/${id}/`,
    delete: (id) => `/api/vscuProductManagement/save-item/${id}/`,
  },
  purchases: {
    sync: '/api/vscuPurchaseTransactionManagement/select-trns-purchase-sales/',
    list: '/api/vscuPurchaseTransactionManagement/select-trns-purchase-sales/',
    details: (id) => `/api/vscuPurchaseTransactionManagement/select-trns-purchase-sales/${id}/`,
    //
    post: '/api/vscuPurchaseTransactionManagement/save-purchase/',
  },
  customer: {
    list: '/api/vscuBranchInformationManagement/branch-customers/',
    post: '/api/vscuBranchInformationManagement/branch-customers/',
    put: (id) => `/api/vscuBranchInformationManagement/branch-customers/${id}/`,
  },
  user: {
    list: '/api/vscuBranchInformationManagement/branch-users/',
    post: '/api/vscuBranchInformationManagement/branch-users/',
    put: (id) => `/api/vscuBranchInformationManagement/branch-users/${id}/`,
  },
  sale: {
    list: '/api/vscuSalesTransactionManagement/save-sale/',
    details: (id) => `/api/vscuSalesTransactionManagement/save-sale/${id}/`,
    post: '/api/vscuSalesTransactionManagement/save-sale/',
    delete: '/api/vscuSalesTransactionManagement/save-sale/',
    receipt: (id) => `/api/receipts/sale/${id}/`,
  },
  code: {
    sync: '/api/vscuCodeDataManagement/codes/',
    list: '/api/vscuCodeDataManagement/codes/',
    post: '/api/vscuCodeDataManagement/codes/',
    details: '/api/vscuCodeDataManagement/codes/details/',
  },
  items_class: {
    sync: '/api/vscuCodeDataManagement/items-class/',
    list: '/api/vscuCodeDataManagement/items-class/',
    post: '/api/vscuCodeDataManagement/items-class/',
  },
  branch: {
    sync: '/api/vscuCodeDataManagement/branches/',
    list: '/api/vscuCodeDataManagement/branches/',
    details: '/api/vscuCodeDataManagement/branches/',
    post: '/api/vscuCodeDataManagement/branches/',
  },
  notice: {
    sync: '/api/vscuCodeDataManagement/notices/',
    list: '/api/vscuCodeDataManagement/notices/',
    details: '/api/vscuCodeDataManagement/notices/',
    post: '/api/vscuCodeDataManagement/notices/',
  },
  save_stock_master: {
    list: '/api/vscuStockManagement/save-stock-master/',
    post: '/api/vscuStockManagement/save-stock-master/',
  },
  select_stock_items: {
    sync: '/api/vscuStockManagement/select-stock-items/',
    list: '/api/vscuStockManagement/select-stock-items/',
    details: (id) => `/api/vscuStockManagement/select-stock-items/${id}/`,
  },
  save_stock_items: {
    post: '/api/vscuStockManagement/save-stock-items/',
    list: '/api/vscuStockManagement/save-stock-items/',
    details: (id) => `/api/vscuStockManagement/save-stock-items/${id}/`,
  },
  initialize: {
    post: '/api/vscuInitInfoManagement/select-initialize/',
    list: '/api/vscuInitInfoManagement/select-initialize/',
    details: (id) => `/api/vscuInitInfoManagement/select-initialize/${id}/`,
  },
  tenant: {
    create: '/api/core/api/tenants/',
    list: '/api/core/api/tenants/',
    details: (id) => `/api/core/api/tenants/${id}/`,
    update: (id) => `/api/core/api/tenants/${id}/`,
  },
  import_items: {
    sync: '/api/vscuImportedItemManagement/select-import-items/',
    list: '/api/vscuImportedItemManagement/select-import-items/',
    post: '/api/vscuImportedItemManagement/save-import-item/',
  },
  insurance: {
    insurances: '/api/vscuBranchInformationManagement/branch-insurances/',
    insurance: (id) => `/api/vscuBranchInformationManagement/branch-insurances/${id}/`,
    create: '/api/vscuBranchInformationManagement/branch-insurances/',
    update: (id) => `/api/vscuBranchInformationManagement/branch-insurances/${id}/`,
    delete: (id) => `/api/vscuBranchInformationManagement/branch-insurances/${id}/`,
  },
};
