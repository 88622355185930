import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState = {
  notices: [],
  notice: null,
  activeStep: 0,
  page: 0, // Add this to manage pagination
  rowsPerPage: 25, // Set the default rows per page
  totalItems: 0, // Track total items available
  noticesStatus: {
    loading: false,
    empty: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    // GET NOTICES
    getNoticesStart(state) {
      state.noticesStatus.loading = true;
      state.noticesStatus.empty = false;
      state.noticesStatus.error = null;
    },

    getNoticesFailure(state, action) {
      state.noticesStatus.loading = false;
      state.noticesStatus.empty = false;
      state.noticesStatus.error = action.payload;
    },

    getNoticesSuccess(state, action) {
      const { notices, totalItems } = action.payload;
      state.notices = notices;
      state.totalItems = totalItems;
      state.noticesStatus.loading = false;
      state.noticesStatus.empty = !notices.length;
      state.noticesStatus.error = null;
    },
  },
});

export const { getNoticesStart, getNoticesFailure, getNoticesSuccess } = slice.actions;

export default slice.reducer;

export const getNotices = () => async (dispatch) => {
  dispatch(getNoticesStart());
  try {
    const response = await axios.get(API_ENDPOINTS.notice.list);
    console.log('GET NOTICES RESPONSE:', response.data.results);
    const { results, count } = response.data;
    dispatch(getNoticesSuccess({ notices: results, totalItems: count }));
  } catch (error) {
    dispatch(getNoticesFailure(error));
  }
};

export function syncNoticesFromEtims({ data = { lastReqDt: '20200101000000' } }) {
  return async () => {
    try {
      console.log('Starting notice synchronization with eTIMS...');
      const response = await axios.post(API_ENDPOINTS.notice.sync, data);
      console.log('Notices sync successful:', response.data);
      return response.data; // Ensure a value is returned here
    } catch (error) {
      console.error('Failed to sync notice from eTIMS:', error);
      return error;
    }
  };
}
