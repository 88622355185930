import { createSlice } from '@reduxjs/toolkit';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  categoryLevel: [],
  locale: [],
  taxOffice: [],
  purchaseReceiptType: [],
  salesReceiptType: [],
  bank: [],
  inventoryAdjustmentReason: [],
  purchaseStatus: [],
  currency: [],
  refundReason: [],
  registrationType: [],
  importItemStatus: [],
  itemType: [],
  packingUnit: [],
  transactionType: [],
  stockIOType: [], // Stock I/O Type
  saleStatus: [],
  quantityUnit: [],
  branchStatus: [],
  paymentType: [],
  country: [],
  taxationType: [],

  status: {
    categoryLevel: { loading: false, empty: false, error: null },
    locale: { loading: false, empty: false, error: null },
    taxOffice: { loading: false, empty: false, error: null },
    purchaseReceiptType: { loading: false, empty: false, error: null },
    salesReceiptType: { loading: false, empty: false, error: null },
    bank: { loading: false, empty: false, error: null },
    inventoryAdjustmentReason: { loading: false, empty: false, error: null },
    purchaseStatus: { loading: false, empty: false, error: null },
    currency: { loading: false, empty: false, error: null },
    refundReason: { loading: false, empty: false, error: null },
    registrationType: { loading: false, empty: false, error: null },
    importItemStatus: { loading: false, empty: false, error: null },
    itemType: { loading: false, empty: false, error: null },
    packingUnit: { loading: false, empty: false, error: null },
    transactionType: { loading: false, empty: false, error: null },
    stockIOType: { loading: false, empty: false, error: null }, // Stock I/O Type
    saleStatus: { loading: false, empty: false, error: null },
    quantityUnit: { loading: false, empty: false, error: null },
    branchStatus: { loading: false, empty: false, error: null },
    paymentType: { loading: false, empty: false, error: null },
    country: { loading: false, empty: false, error: null },
    taxationType: { loading: false, empty: false, error: null },
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'items_class',
  initialState,
  reducers: {
    // Shared Start Action
    getCodeDetailsStart(state, action) {
      const { key } = action.payload;
      state.status[key].loading = true;
      state.status[key].empty = false;
      state.status[key].error = null;
    },

    // Shared Failure Action
    getCodeDetailsFailure(state, action) {
      const { key, error } = action.payload;
      state.status[key].loading = false;
      state.status[key].empty = false;
      state.status[key].error = error;
    },

    // Shared Success Action
    getCodeDetailsSuccess(state, action) {
      const { key, data } = action.payload;
      state[key] = data;
      state.status[key].loading = false;
      state.status[key].empty = !data.length;
      state.status[key].error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getCodeDetailsStart, getCodeDetailsFailure, getCodeDetailsSuccess } = slice.actions;

// ----------------------------------------------------------------------

// Helper function to map `cdCls` values to state keys
const cdClsMap = {
  49: 'categoryLevel',
  48: 'locale',
  45: 'taxOffice',
  38: 'purchaseReceiptType',
  37: 'salesReceiptType',
  36: 'bank',
  35: 'inventoryAdjustmentReason',
  34: 'purchaseStatus',
  33: 'currency',
  32: 'refundReason',
  31: 'registrationType',
  26: 'importItemStatus',
  24: 'itemType',
  17: 'packingUnit',
  14: 'transactionType',
  12: 'stockIOType', // Stock I/O Type
  11: 'saleStatus',
  10: 'quantityUnit',
  '09': 'branchStatus',
  '07': 'paymentType',
  '05': 'country',
  '04': 'taxationType',
};

// Unified function to get details based on `cdCls`
export function getCodeDetails(cdCls = null, options = {}) {
  return async (dispatch) => {
    const key = cdClsMap[cdCls];
    if (!key) return;

    dispatch(getCodeDetailsStart({ key }));

    try {
      const response = await axios.get(API_ENDPOINTS.code.details, {
        params: {
          limit: 25,
          offset: 0,
          ...options,
          selectCodesId__cdCls: cdCls,
        },
      });
      console.log('GET CODE DETAILS RESPONSE', response);
      dispatch(getCodeDetailsSuccess({ key, data: response.data.results }));
    } catch (error) {
      dispatch(getCodeDetailsFailure({ key, error }));
    }
  };
}

export function syncCodesFromEtims({ data = { lastReqDt: '20200101000000' } }) {
  return async () => {
    try {
      console.log('Starting codes synchronization with eTIMS...');
      const response = await axios.post(API_ENDPOINTS.code.sync, data);
      console.log('Codes sync successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to sync codes from eTIMS:', error);
      return error;
    }
  };
}
