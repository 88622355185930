export const selectCodes = [
  {
    "cdCls": "04",
    "cdClsNm": "Taxation Type",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": "Tax Rate",
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "A",
        "cdNm": "A-Exempt",
        "cdDesc": "A-Exempt",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": "0",
        "userDfnCd2": "A-EX",
        "userDfnCd3": null
      },
      {
        "cd": "B",
        "cdNm": "B-VAT 16%",
        "cdDesc": "B-VAT 16%",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": "16",
        "userDfnCd2": "B-16%",
        "userDfnCd3": null
      },
      {
        "cd": "C",
        "cdNm": "C-Zero Rated",
        "cdDesc": "C-Zero Rated",
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": "0",
        "userDfnCd2": "TAX C",
        "userDfnCd3": null
      },
      {
        "cd": "E",
        "cdNm": "E-VAT 8%",
        "cdDesc": "E-VAT 8%",
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": "8",
        "userDfnCd2": "E-8%",
        "userDfnCd3": null
      },
      {
        "cd": "D",
        "cdNm": "D-Non VAT",
        "cdDesc": "D-Non VAT",
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": "0",
        "userDfnCd2": "TAX D",
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "05",
    "cdClsNm": "Country",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "AC",
        "cdNm": "ASCENSION ISLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AD",
        "cdNm": "ANDORRA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AE",
        "cdNm": "UNITED ARAB EMIRATES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AG",
        "cdNm": "ANTIGUA AND BARBUDA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AI",
        "cdNm": "ANGUILLA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AL",
        "cdNm": "ALBANIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AM",
        "cdNm": "ARMENIA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AN",
        "cdNm": "NETHERLANDS ANTILLES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 8,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AO",
        "cdNm": "ANGOLA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 9,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AQ",
        "cdNm": "ANTARCTICA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AR",
        "cdNm": "ARGENTINA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AS",
        "cdNm": "AMERICAN SAMOA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AT",
        "cdNm": "AUSTRIA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AU",
        "cdNm": "AUSTRALIA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 14,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AW",
        "cdNm": "ARUBA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 15,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AX",
        "cdNm": "ALAND ISLANDS",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 16,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AZ",
        "cdNm": "AZERBAIJAN",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 17,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BA",
        "cdNm": "BOSNIA AND HERZEGOVINA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 18,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BB",
        "cdNm": "BARBADOS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 19,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BD",
        "cdNm": "BANGLADESH ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 20,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BE",
        "cdNm": "BELGIUM ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 21,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BF",
        "cdNm": "BURKINA FASO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 22,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BG",
        "cdNm": "BULGARIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 23,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BH",
        "cdNm": "BAHRAIN",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 24,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BI",
        "cdNm": "BURUNDI ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 25,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BJ",
        "cdNm": "BENIN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 26,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BM",
        "cdNm": "BERMUDA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 27,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BN",
        "cdNm": "BRUNEI DARUSSALAM ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 28,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BO",
        "cdNm": "BOLIVIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 29,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BR",
        "cdNm": "BRAZIL ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 30,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BS",
        "cdNm": "BAHAMAS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 31,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BT",
        "cdNm": "BHUTAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 32,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BV",
        "cdNm": "BOUVET ISLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 33,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BW",
        "cdNm": "BOTSWANA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 34,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BY",
        "cdNm": "BELARUS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 35,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BZ",
        "cdNm": "BELIZE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 36,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CA",
        "cdNm": "CANADA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 37,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CC",
        "cdNm": "COCOS (KEELING) ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 38,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CD",
        "cdNm": "CONGO, DEMOCRATIC REPUBLIC ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 39,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CF",
        "cdNm": "CENTRAL AFRICAN REPUBLIC ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 40,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CG",
        "cdNm": "CONGO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 41,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CH",
        "cdNm": "SWITZERLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 42,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CI",
        "cdNm": "COTE D'IVOIRE (IVORY COAST) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 43,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CK",
        "cdNm": "COOK ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 44,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CL",
        "cdNm": "CHILE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 45,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CM",
        "cdNm": "CAMEROON ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 46,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CN",
        "cdNm": "CHINA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 47,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CO",
        "cdNm": "COLOMBIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 48,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CR",
        "cdNm": "COSTA RICA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 49,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CS",
        "cdNm": "CZECHOSLOVAKIA (FORMER) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 50,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CU",
        "cdNm": "CUBA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 51,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CV",
        "cdNm": "CAPE VERDE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 52,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CX",
        "cdNm": "CHRISTMAS ISLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 53,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CY",
        "cdNm": "CYPRUS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 54,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CZ",
        "cdNm": "CZECH REPUBLIC ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 55,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DE",
        "cdNm": "GERMANY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 56,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DJ",
        "cdNm": "DJIBOUTI ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 57,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DK",
        "cdNm": "DENMARK ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 58,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DM",
        "cdNm": "DOMINICA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 59,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DO",
        "cdNm": "DOMINICAN REPUBLIC ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 60,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DZ",
        "cdNm": "ALGERIA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 61,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "EC",
        "cdNm": "ECUADOR ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 62,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "EE",
        "cdNm": "ESTONIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 63,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "EG",
        "cdNm": "EGYPT ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 64,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "EH",
        "cdNm": "WESTERN SAHARA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 65,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ER",
        "cdNm": "ERITREA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 66,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ES",
        "cdNm": "SPAIN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 67,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ET",
        "cdNm": "ETHIOPIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 68,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "EU",
        "cdNm": "EUROPEAN UNION ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 69,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FI",
        "cdNm": "FINLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 70,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FJ",
        "cdNm": "FIJI ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 71,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FK",
        "cdNm": "FALKLAND ISLANDS (MALVINAS) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 72,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FM",
        "cdNm": "MICRONESIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 73,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FO",
        "cdNm": "FAROE ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 74,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FR",
        "cdNm": "FRANCE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 75,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FX",
        "cdNm": "FRANCE, METROPOLITAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 76,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GA",
        "cdNm": "GABON ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 77,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GB",
        "cdNm": "GREAT BRITAIN (UK) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 78,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GD",
        "cdNm": "GRENADA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 79,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GE",
        "cdNm": "GEORGIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 80,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GF",
        "cdNm": "FRENCH GUIANA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 81,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GG",
        "cdNm": "GUERNSEY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 82,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GH",
        "cdNm": "GHANA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 83,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GI",
        "cdNm": "GIBRALTAR ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 84,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GL",
        "cdNm": "GREENLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 85,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GM",
        "cdNm": "GAMBIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 86,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GN",
        "cdNm": "GUINEA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 87,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GP",
        "cdNm": "GUADELOUPE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 88,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GQ",
        "cdNm": "EQUATORIAL GUINEA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 89,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GR",
        "cdNm": "GREECE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 90,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GS",
        "cdNm": "S. GEORGIA AND S. SANDWICH ISLS. ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 91,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GT",
        "cdNm": "GUATEMALA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 92,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GU",
        "cdNm": "GUAM ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 93,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GW",
        "cdNm": "GUINEA     BISSAU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 94,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GY",
        "cdNm": "GUYANA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 95,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HK",
        "cdNm": "HONG KONG ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 96,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HM",
        "cdNm": "HEARD AND MCDONALD ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 97,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HN",
        "cdNm": "HONDURAS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 98,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HR",
        "cdNm": "CROATIA (HRVATSKA) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 99,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HT",
        "cdNm": "HAITI ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 100,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HU",
        "cdNm": "HUNGARY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 101,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ID",
        "cdNm": "INDONESIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 102,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IE",
        "cdNm": "IRELAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 103,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IL",
        "cdNm": "ISRAEL ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 104,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IM",
        "cdNm": "ISLE OF MAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 105,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IN",
        "cdNm": "INDIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 106,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IO",
        "cdNm": "BRITISH INDIAN OCEAN TERRITORY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 107,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IQ",
        "cdNm": "IRAQ ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 108,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IR",
        "cdNm": "IRAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 109,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IS",
        "cdNm": "ICELAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 110,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IT",
        "cdNm": "ITALY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 111,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JE",
        "cdNm": "JERSEY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 112,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JM",
        "cdNm": "JAMAICA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 113,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JO",
        "cdNm": "JORDAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 114,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JP",
        "cdNm": "JAPAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 115,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KE",
        "cdNm": "KENYA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 116,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KG",
        "cdNm": "KYRGYZSTAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 117,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KH",
        "cdNm": "CAMBODIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 118,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KI",
        "cdNm": "KIRIBATI ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 119,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KM",
        "cdNm": "COMOROS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 120,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KN",
        "cdNm": "SAINT KITTS AND NEVIS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 121,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KP",
        "cdNm": "NORTH KOREA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 122,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KR",
        "cdNm": "SOUTH KOREA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 123,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KW",
        "cdNm": "KUWAIT ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 124,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KY",
        "cdNm": "CAYMAN ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 125,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KZ",
        "cdNm": "KAZAKHSTAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 126,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LA",
        "cdNm": "LAOS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 127,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LB",
        "cdNm": "LEBANON ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 128,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LC",
        "cdNm": "SAINT LUCIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 129,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LI",
        "cdNm": "LIECHTENSTEIN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 130,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LK",
        "cdNm": "SRI LANKA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 131,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LR",
        "cdNm": "LIBERIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 132,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LS",
        "cdNm": "LESOTHO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 133,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LT",
        "cdNm": "LITHUANIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 134,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LU",
        "cdNm": "LUXEMBOURG ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 135,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LV",
        "cdNm": "LATVIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 136,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LY",
        "cdNm": "LIBYA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 137,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MA",
        "cdNm": "MOROCCO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 138,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MC",
        "cdNm": "MONACO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 139,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MD",
        "cdNm": "MOLDOVA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 140,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ME",
        "cdNm": "MONTENEGRO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 141,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MG",
        "cdNm": "MADAGASCAR ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 142,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MH",
        "cdNm": "MARSHALL ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 143,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MK",
        "cdNm": "F.Y.R.O.M. (MACEDONIA) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 144,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ML",
        "cdNm": "MALI ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 145,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MM",
        "cdNm": "MYANMAR ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 146,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MN",
        "cdNm": "MONGOLIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 147,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MO",
        "cdNm": "MACAU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 148,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MP",
        "cdNm": "NORTHERN MARIANA ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 149,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MQ",
        "cdNm": "MARTINIQUE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 150,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MR",
        "cdNm": "MAURITANIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 151,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MS",
        "cdNm": "MONTSERRAT ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 152,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MT",
        "cdNm": "MALTA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 153,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MU",
        "cdNm": "MAURITIUS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 154,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MV",
        "cdNm": "MALDIVES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 155,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MW",
        "cdNm": "MALAWI ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 156,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MX",
        "cdNm": "MEXICO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 157,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MY",
        "cdNm": "MALAYSIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 158,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MZ",
        "cdNm": "MOZAMBIQUE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 159,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NA",
        "cdNm": "NAMIBIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 160,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NC",
        "cdNm": "NEW CALEDONIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 161,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NE",
        "cdNm": "NIGER ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 162,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NF",
        "cdNm": "NORFOLK ISLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 163,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NG",
        "cdNm": "NIGERIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 164,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NI",
        "cdNm": "NICARAGUA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 165,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NL",
        "cdNm": "NETHERLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 166,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NO",
        "cdNm": "NORWAY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 167,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NP",
        "cdNm": "NEPAL ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 168,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NR",
        "cdNm": "NAURU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 169,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NT",
        "cdNm": "NEUTRAL ZONE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 170,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NU",
        "cdNm": "NIUE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 171,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NZ",
        "cdNm": "NEW ZEALAND (AOTEAROA) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 172,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "OM",
        "cdNm": "OMAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 173,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PA",
        "cdNm": "PANAMA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 174,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PE",
        "cdNm": "PERU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 175,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PF",
        "cdNm": "FRENCH POLYNESIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 176,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PG",
        "cdNm": "PAPUA NEW GUINEA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 177,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PH",
        "cdNm": "PHILIPPINES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 178,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PK",
        "cdNm": "PAKISTAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 179,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PL",
        "cdNm": "POLAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 180,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PM",
        "cdNm": "ST. PIERRE AND MIQUELON ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 181,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PN",
        "cdNm": "PITCAIRN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 182,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PR",
        "cdNm": "PUERTO RICO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 183,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PS",
        "cdNm": "PALESTINIAN TERRITORY, OCCUPIED ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 184,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PT",
        "cdNm": "PORTUGAL ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 185,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PW",
        "cdNm": "PALAU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 186,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PY",
        "cdNm": "PARAGUAY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 187,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "QA",
        "cdNm": "QATAR ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 188,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RE",
        "cdNm": "REUNION ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 189,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RO",
        "cdNm": "ROMANIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 190,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RS",
        "cdNm": "SERBIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 191,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RU",
        "cdNm": "RUSSIAN FEDERATION ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 192,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RW",
        "cdNm": "RWANDA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 193,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SA",
        "cdNm": "SAUDI ARABIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 194,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SB",
        "cdNm": "SOLOMON ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 195,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SC",
        "cdNm": "SEYCHELLES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 196,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SD",
        "cdNm": "SUDAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 197,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SE",
        "cdNm": "SWEDEN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 198,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SG",
        "cdNm": "SINGAPORE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 199,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SH",
        "cdNm": "ST. HELENA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 200,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SI",
        "cdNm": "SLOVENIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 201,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SJ",
        "cdNm": "SVALBARD & JAN MAYEN ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 202,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SK",
        "cdNm": "SLOVAK REPUBLIC ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 203,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SL",
        "cdNm": "SIERRA LEONE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 204,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SM",
        "cdNm": "SAN MARINO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 205,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SN",
        "cdNm": "SENEGAL ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 206,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SO",
        "cdNm": "SOMALIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 207,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SR",
        "cdNm": "SURINAME ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 208,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ST",
        "cdNm": "SAO TOME AND PRINCIPE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 209,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SU",
        "cdNm": "USSR (FORMER) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 210,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SV",
        "cdNm": "EL SALVADOR ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 211,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SY",
        "cdNm": "SYRIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 212,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SZ",
        "cdNm": "SWAZILAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 213,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TC",
        "cdNm": "TURKS AND CAICOS ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 214,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TD",
        "cdNm": "CHAD ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 215,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TF",
        "cdNm": "FRENCH SOUTHERN TERRITORIES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 216,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TG",
        "cdNm": "TOGO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 217,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TH",
        "cdNm": "THAILAND ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 218,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TJ",
        "cdNm": "TAJIKISTAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 219,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TK",
        "cdNm": "TOKELAU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 220,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TM",
        "cdNm": "TURKMENISTAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 221,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TN",
        "cdNm": "TUNISIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 222,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TO",
        "cdNm": "TONGA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 223,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TP",
        "cdNm": "EAST TIMOR ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 224,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TR",
        "cdNm": "TURKEY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 225,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TT",
        "cdNm": "TRINIDAD AND TOBAGO ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 226,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TV",
        "cdNm": "TUVALU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 227,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TW",
        "cdNm": "TAIWAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 228,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TZ",
        "cdNm": "TANZANIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 229,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UA",
        "cdNm": "UKRAINE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 230,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UG",
        "cdNm": "UGANDA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 231,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UK",
        "cdNm": "UNITED KINGDOM ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 232,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UM",
        "cdNm": "US MINOR OUTLYING ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 233,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "US",
        "cdNm": "UNITED STATES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 234,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UY",
        "cdNm": "URUGUAY ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 235,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UZ",
        "cdNm": "UZBEKISTAN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 236,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VA",
        "cdNm": "VATICAN CITY STATE (HOLY SEE) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 237,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VC",
        "cdNm": "SAINT VINCENT & THE GRENADINES ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 238,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VE",
        "cdNm": "VENEZUELA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 239,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VG",
        "cdNm": "BRITISH VIRGIN ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 240,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VI",
        "cdNm": "VIRGIN ISLANDS (U.S.) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 241,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VN",
        "cdNm": "VIET NAM ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 242,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VU",
        "cdNm": "VANUATU ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 243,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "WF",
        "cdNm": "WALLIS AND FUTUNA ISLANDS ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 244,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "WS",
        "cdNm": "SAMOA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 245,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "YE",
        "cdNm": "YEMEN ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 246,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "YT",
        "cdNm": "MAYOTTE ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 247,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "YU",
        "cdNm": "SERBIA AND MONTENEGRO (FORMER) ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 248,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ZA",
        "cdNm": "SOUTH AFRICA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 249,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ZM",
        "cdNm": "ZAMBIA ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 250,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ZR",
        "cdNm": "(ZAIRE) SEE CD CONGO, DEMOCRATIC REPUBLIC ",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 251,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ZW",
        "cdNm": "ZIMBABWE",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 252,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "07",
    "cdClsNm": "Payment Type",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "01",
        "cdNm": "CASH",
        "cdDesc": "CASH",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "02",
        "cdNm": "CREDIT",
        "cdDesc": "CREDIT",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "03",
        "cdNm": "CASH/CREDIT",
        "cdDesc": "CASH/CREDIT",
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "04",
        "cdNm": "BANK CHECK",
        "cdDesc": "BANK CHECK PAYMENT",
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "05",
        "cdNm": "DEBIT&CREDIT CARD",
        "cdDesc": "PAYMENT USING CARD",
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "06",
        "cdNm": "MOBILE MONEY",
        "cdDesc": "ANY TRANSACTION USING MOBILE MONEY SYSTEM",
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "07",
        "cdNm": "OTHER",
        "cdDesc": "OTHER MEANS OF PAYMENT",
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "09",
    "cdClsNm": "Branch Status",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "01",
        "cdNm": "Open",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "02",
        "cdNm": "Closed",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "10",
    "cdClsNm": "Quantity Unit",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "4B",
        "cdNm": "Pair",
        "cdDesc": "Pair",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AV",
        "cdNm": "Cap",
        "cdDesc": "Cap",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BA",
        "cdNm": "Barrel",
        "cdDesc": "Barrel",
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BE",
        "cdNm": "bundle",
        "cdDesc": "bundle",
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BG",
        "cdNm": "bag",
        "cdDesc": "bag",
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BL",
        "cdNm": "block",
        "cdDesc": "block",
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BLL",
        "cdNm": "BLL Barrel (petroleum) (158,987 dm3)",
        "cdDesc": "BLL Barrel (petroleum) (158,987 dm3)",
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BX",
        "cdNm": "box",
        "cdDesc": "box",
        "useYn": "Y",
        "srtOrd": 8,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CA",
        "cdNm": "Can",
        "cdDesc": "Can",
        "useYn": "Y",
        "srtOrd": 9,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CEL",
        "cdNm": "Cell",
        "cdDesc": "Cell",
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CMT",
        "cdNm": "centimetre",
        "cdDesc": "centimetre",
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CR",
        "cdNm": "CARAT",
        "cdDesc": "CARAT",
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DR",
        "cdNm": "Drum",
        "cdDesc": "Drum",
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DZ",
        "cdNm": "Dozen",
        "cdDesc": "Dozen",
        "useYn": "Y",
        "srtOrd": 14,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GLL",
        "cdNm": "Gallon",
        "cdDesc": "Gallon",
        "useYn": "Y",
        "srtOrd": 15,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GRM",
        "cdNm": "Gram",
        "cdDesc": "Gram",
        "useYn": "Y",
        "srtOrd": 16,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GRO",
        "cdNm": "Gross",
        "cdDesc": "Gross",
        "useYn": "Y",
        "srtOrd": 17,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KG",
        "cdNm": "Kilo-Gramme",
        "cdDesc": "Kilo-Gramme",
        "useYn": "Y",
        "srtOrd": 18,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KTM",
        "cdNm": "kilometre",
        "cdDesc": "kilometre",
        "useYn": "Y",
        "srtOrd": 19,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KWT",
        "cdNm": "kilowatt",
        "cdDesc": "kilowatt",
        "useYn": "Y",
        "srtOrd": 20,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "L",
        "cdNm": "Litre",
        "cdDesc": "Litre",
        "useYn": "Y",
        "srtOrd": 21,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LBR",
        "cdNm": "pound",
        "cdDesc": "pound",
        "useYn": "Y",
        "srtOrd": 22,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LK",
        "cdNm": "link",
        "cdDesc": "link",
        "useYn": "Y",
        "srtOrd": 23,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LTR",
        "cdNm": "Litre",
        "cdDesc": "Litre",
        "useYn": "Y",
        "srtOrd": 24,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "M",
        "cdNm": "Metre",
        "cdDesc": "Metre",
        "useYn": "Y",
        "srtOrd": 25,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "M2",
        "cdNm": "Square Metre",
        "cdDesc": "Square Metre",
        "useYn": "Y",
        "srtOrd": 26,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "M3",
        "cdNm": "Cubic Metre",
        "cdDesc": "Cubic Metre",
        "useYn": "Y",
        "srtOrd": 27,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MGM",
        "cdNm": "milligram",
        "cdDesc": "milligram",
        "useYn": "Y",
        "srtOrd": 28,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MTR",
        "cdNm": "metre",
        "cdDesc": "metre",
        "useYn": "Y",
        "srtOrd": 29,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MWT",
        "cdNm": "megawatt hour (1000 kW.h)",
        "cdDesc": "megawatt hour (1000 kW.h)",
        "useYn": "Y",
        "srtOrd": 30,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NO",
        "cdNm": "Number",
        "cdDesc": "Number",
        "useYn": "Y",
        "srtOrd": 31,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NX",
        "cdNm": "part per thousand",
        "cdDesc": "part per thousand",
        "useYn": "Y",
        "srtOrd": 32,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PA",
        "cdNm": "packet",
        "cdDesc": "packet",
        "useYn": "Y",
        "srtOrd": 33,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PG",
        "cdNm": "plate",
        "cdDesc": "plate",
        "useYn": "Y",
        "srtOrd": 34,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PR",
        "cdNm": "pair",
        "cdDesc": "pair",
        "useYn": "Y",
        "srtOrd": 35,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RL",
        "cdNm": "reel",
        "cdDesc": "reel",
        "useYn": "Y",
        "srtOrd": 36,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RO",
        "cdNm": "roll",
        "cdDesc": "roll",
        "useYn": "Y",
        "srtOrd": 37,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SET",
        "cdNm": "set",
        "cdDesc": "set",
        "useYn": "Y",
        "srtOrd": 38,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ST",
        "cdNm": "sheet",
        "cdDesc": "sheet",
        "useYn": "Y",
        "srtOrd": 39,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TNE",
        "cdNm": "tonne (metric ton)",
        "cdDesc": "tonne (metric ton)",
        "useYn": "Y",
        "srtOrd": 40,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TU",
        "cdNm": "tube",
        "cdDesc": "tube",
        "useYn": "Y",
        "srtOrd": 41,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "U",
        "cdNm": "Pieces/item [Number]",
        "cdDesc": "Pieces/item [Number]",
        "useYn": "Y",
        "srtOrd": 42,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "YRD",
        "cdNm": "yard",
        "cdDesc": "yard",
        "useYn": "Y",
        "srtOrd": 43,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "11",
    "cdClsNm": "Sale Status",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "01",
        "cdNm": "Wait for Approval",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "02",
        "cdNm": "Approved",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "03",
        "cdNm": "Cancel Requested",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "04",
        "cdNm": "Canceled",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "05",
        "cdNm": "Refunded",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "06",
        "cdNm": "Transferred",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "12",
    "cdClsNm": "Stock I/O Type",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "01",
        "cdNm": "Import",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "02",
        "cdNm": "Purchase",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "03",
        "cdNm": "Return",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "04",
        "cdNm": "Stock Movement",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "05",
        "cdNm": "Processing",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "06",
        "cdNm": "Adjustment",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "11",
        "cdNm": "Sale",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "12",
        "cdNm": "Return",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "13",
        "cdNm": "Stock Movement",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "14",
        "cdNm": "Processing",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 14,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "15",
        "cdNm": "Discarding",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 15,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "16",
        "cdNm": "Adjustment",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 16,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "14",
    "cdClsNm": "Transaction Type",
    "cdClsDesc": "Sales Type and Purchase Type",
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "C",
        "cdNm": "Copy",
        "cdDesc": "Copy",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "N",
        "cdNm": "Normal",
        "cdDesc": "Normal",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "P",
        "cdNm": "Proforma",
        "cdDesc": "Proforma",
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "T",
        "cdNm": "Training",
        "cdDesc": "Training",
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "17",
    "cdClsNm": "Packing Unit",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "AM",
        "cdNm": "Ampoule",
        "cdDesc": "Ampoule",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BA",
        "cdNm": "Barrel",
        "cdDesc": "Barrel",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BC",
        "cdNm": "Bottlecrate",
        "cdDesc": "Bottlecrate",
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BE",
        "cdNm": "Bundle",
        "cdDesc": "Bundle",
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BF",
        "cdNm": "Balloon, non-protected",
        "cdDesc": "Balloon, non-protected",
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BG",
        "cdNm": "Bag",
        "cdDesc": "Bag",
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BJ",
        "cdNm": "Bucket",
        "cdDesc": "Bucket",
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BK",
        "cdNm": "Basket",
        "cdDesc": "Basket",
        "useYn": "Y",
        "srtOrd": 8,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BL",
        "cdNm": "Bale",
        "cdDesc": "Bale",
        "useYn": "Y",
        "srtOrd": 9,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BQ",
        "cdNm": "Bottle, protected cylindrical",
        "cdDesc": "Bottle, protected cylindrical",
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BR",
        "cdNm": "Bar",
        "cdDesc": "Bar",
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BV",
        "cdNm": "Bottle, bulbous",
        "cdDesc": "Bottle, bulbous",
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BZ",
        "cdNm": "Bag",
        "cdDesc": "Bag",
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CA",
        "cdNm": "Can",
        "cdDesc": "Can",
        "useYn": "Y",
        "srtOrd": 14,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CH",
        "cdNm": "Chest",
        "cdDesc": "Chest",
        "useYn": "Y",
        "srtOrd": 15,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CJ",
        "cdNm": "Coffin",
        "cdDesc": "Coffin",
        "useYn": "Y",
        "srtOrd": 16,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CL",
        "cdNm": "Coil",
        "cdDesc": "Coil",
        "useYn": "Y",
        "srtOrd": 17,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CR",
        "cdNm": "Wooden Box, Wooden Case",
        "cdDesc": "Wooden Box, Wooden Case",
        "useYn": "Y",
        "srtOrd": 18,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CS",
        "cdNm": "Cassette",
        "cdDesc": "Cassette",
        "useYn": "Y",
        "srtOrd": 19,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CT",
        "cdNm": "Carton",
        "cdDesc": "Carton",
        "useYn": "Y",
        "srtOrd": 20,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CTN",
        "cdNm": "Container",
        "cdDesc": "Container",
        "useYn": "Y",
        "srtOrd": 21,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CY",
        "cdNm": "Cylinder",
        "cdDesc": "Cylinder",
        "useYn": "Y",
        "srtOrd": 22,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DR",
        "cdNm": "Drum",
        "cdDesc": "Drum",
        "useYn": "Y",
        "srtOrd": 23,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GT",
        "cdNm": "Extra Countable Item",
        "cdDesc": "Extra Countable Item",
        "useYn": "Y",
        "srtOrd": 24,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HH",
        "cdNm": "Hand Baggage",
        "cdDesc": "Hand Baggage",
        "useYn": "Y",
        "srtOrd": 25,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IZ",
        "cdNm": "Ingots",
        "cdDesc": "Ingots",
        "useYn": "Y",
        "srtOrd": 26,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JR",
        "cdNm": "Jar",
        "cdDesc": "Jar",
        "useYn": "Y",
        "srtOrd": 27,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JU",
        "cdNm": "Jug",
        "cdDesc": "Jug",
        "useYn": "Y",
        "srtOrd": 28,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JY",
        "cdNm": "Jerry CAN Cylindrical",
        "cdDesc": "Jerry CAN Cylindrical",
        "useYn": "Y",
        "srtOrd": 29,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KZ",
        "cdNm": "Canester",
        "cdDesc": "Canester",
        "useYn": "Y",
        "srtOrd": 30,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LZ",
        "cdNm": "Logs, in bundle/bunch/truss",
        "cdDesc": "Logs, in bundle/bunch/truss",
        "useYn": "Y",
        "srtOrd": 31,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NT",
        "cdNm": "Net",
        "cdDesc": "Net",
        "useYn": "Y",
        "srtOrd": 32,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "OU",
        "cdNm": "Non-Exterior Packaging Unit",
        "cdDesc": "Non-Exterior Packaging Unit",
        "useYn": "Y",
        "srtOrd": 33,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PD",
        "cdNm": "Poddon",
        "cdDesc": "Poddon",
        "useYn": "Y",
        "srtOrd": 34,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PG",
        "cdNm": "Plate",
        "cdDesc": "Plate",
        "useYn": "Y",
        "srtOrd": 35,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PI",
        "cdNm": "Pipe",
        "cdDesc": "Pipe",
        "useYn": "Y",
        "srtOrd": 36,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PO",
        "cdNm": "Pilot",
        "cdDesc": "Pilot",
        "useYn": "Y",
        "srtOrd": 37,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PU",
        "cdNm": "Traypack",
        "cdDesc": "Traypack",
        "useYn": "Y",
        "srtOrd": 38,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RL",
        "cdNm": "Reel",
        "cdDesc": "Reel",
        "useYn": "Y",
        "srtOrd": 39,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RO",
        "cdNm": "Roll",
        "cdDesc": "Roll",
        "useYn": "Y",
        "srtOrd": 40,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RZ",
        "cdNm": "Rods, in bundle/bunch/truss",
        "cdDesc": "Rods, in bundle/bunch/truss",
        "useYn": "Y",
        "srtOrd": 41,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SK",
        "cdNm": "Skeletoncase",
        "cdDesc": "Skeletoncase",
        "useYn": "Y",
        "srtOrd": 42,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TY",
        "cdNm": "Tank, cylindrical",
        "cdDesc": "Tank, cylindrical",
        "useYn": "Y",
        "srtOrd": 43,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VG",
        "cdNm": "Bulk,gas(at 1031 mbar 15 oC)",
        "cdDesc": "Bulk,gas(at 1031 mbar 15 oC)",
        "useYn": "Y",
        "srtOrd": 44,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VL",
        "cdNm": "Bulk,liquid(at normal temperature/pressure)",
        "cdDesc": "Bulk,liquid(at normal temperature/pressure)",
        "useYn": "Y",
        "srtOrd": 45,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VO",
        "cdNm": "Bulk, solid, large particles(nodules)",
        "cdDesc": "Bulk, solid, large particles(\"nodules\")",
        "useYn": "Y",
        "srtOrd": 46,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VQ",
        "cdNm": "Bulk, gas (liquefied at abnormal temperature/pressure)",
        "cdDesc": "Bulk, gas(liquefied at abnormal temperature/pressure)",
        "useYn": "Y",
        "srtOrd": 47,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VR",
        "cdNm": "Bulk, solid, granular particles(grains)",
        "cdDesc": "Bulk, solid, granular particles(\"grains\")",
        "useYn": "Y",
        "srtOrd": 48,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VT",
        "cdNm": "Extra Bulk Item",
        "cdDesc": "Extra Bulk Item",
        "useYn": "Y",
        "srtOrd": 49,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VY",
        "cdNm": "Bulk, fine particles(powder)",
        "cdDesc": "Bulk, fine particles(\"powder\")",
        "useYn": "Y",
        "srtOrd": 50,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ML",
        "cdNm": "Mills",
        "cdDesc": "cigarette Mills",
        "useYn": "Y",
        "srtOrd": 51,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TN",
        "cdNm": "TAN",
        "cdDesc": "1TAN REFER TO 20BAGS",
        "useYn": "Y",
        "srtOrd": 52,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "24",
    "cdClsNm": "Item Type",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "1",
        "cdNm": "Raw Material",
        "cdDesc": "Raw Material",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "2",
        "cdNm": "Finished Product",
        "cdDesc": "Finished Product",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "3",
        "cdNm": "Service",
        "cdDesc": "Service without stock",
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "26",
    "cdClsNm": "Import Item Status",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "1",
        "cdNm": "Unsent",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "2",
        "cdNm": "Waiting",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "3",
        "cdNm": "Approved",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "4",
        "cdNm": "Cancelled",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "31",
    "cdClsNm": "Registration Type",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "A",
        "cdNm": "Automatic",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "M",
        "cdNm": "Manual",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "32",
    "cdClsNm": "Refund Reason",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "01",
        "cdNm": "Missing Quantity",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "02",
        "cdNm": "Missing Waiting",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "03",
        "cdNm": "Damaged",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "04",
        "cdNm": "Wasted",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "05",
        "cdNm": "Raw Material Shortage",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "06",
        "cdNm": "Refund",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "07",
        "cdNm": "Wrong Customer PIN",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "08",
        "cdNm": "Wrong Customer name",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 8,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "09",
        "cdNm": "Wrong Amount/price",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 9,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "10",
        "cdNm": "Wrong Quantity",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "11",
        "cdNm": "Wrong Item(s)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "12",
        "cdNm": "Wrong tax type",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "13",
        "cdNm": "Other reason",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "33",
    "cdClsNm": "Currency",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "AED",
        "cdNm": "United Arab Emirates dirham",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AFN",
        "cdNm": "Afghan afghani",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ALL",
        "cdNm": "Albanian lek",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AMD",
        "cdNm": "Armenian dram",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ANG",
        "cdNm": "Netherlands Antillean guilder",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AOA",
        "cdNm": "Angolan kwanza",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ARS",
        "cdNm": "Argentine peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AUD",
        "cdNm": "Australian dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 8,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AWG",
        "cdNm": "Aruban florin",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 9,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "AZN",
        "cdNm": "Azerbaijani manat",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BAM",
        "cdNm": "Bosnia and Herzegovina convertible mark",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BBD",
        "cdNm": "Barbados dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BDT",
        "cdNm": "Bangladeshi taka",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BGN",
        "cdNm": "Bulgarian lev",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 14,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BHD",
        "cdNm": "Bahraini dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 15,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BIF",
        "cdNm": "Burundian franc",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 16,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BMD",
        "cdNm": "Bermudian dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 17,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BND",
        "cdNm": "Brunei dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 18,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BOB",
        "cdNm": "Boliviano",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 19,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BOV",
        "cdNm": "Bolivian Mvdol (funds code)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 20,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BRL",
        "cdNm": "Brazilian real",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 21,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BSD",
        "cdNm": "Bahamian dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 22,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BTN",
        "cdNm": "Bhutanese ngultrum",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 23,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BWP",
        "cdNm": "Botswana pula",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 24,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BYN",
        "cdNm": "New Belarusian ruble",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 25,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BYR",
        "cdNm": "Belarusian ruble",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 26,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "BZD",
        "cdNm": "Belize dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 27,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CAD",
        "cdNm": "Canadian dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 28,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CDF",
        "cdNm": "Congolese franc",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 29,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CHE",
        "cdNm": "WIR Euro (complementary currency)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 30,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CHF",
        "cdNm": "Swiss franc",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 31,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CHW",
        "cdNm": "WIR Franc (complementary currency)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 32,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CLF",
        "cdNm": "Unidad de Fomento (funds code)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 33,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CLP",
        "cdNm": "Chilean peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 34,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CNY",
        "cdNm": "Chinese yuan",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 35,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "COP",
        "cdNm": "Colombian peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 36,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "COU",
        "cdNm": "Unidad de Valor Real (UVR) (funds code)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 37,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CRC",
        "cdNm": "Costa Rican colon",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 38,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CUC",
        "cdNm": "Cuban convertible peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 39,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CUP",
        "cdNm": "Cuban peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 40,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CVE",
        "cdNm": "Cape Verde escudo",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 41,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "CZK",
        "cdNm": "Czech koruna",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 42,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DJF",
        "cdNm": "Djiboutian franc",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 43,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DKK",
        "cdNm": "Danish krone",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 44,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DOP",
        "cdNm": "Dominican peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 45,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "DZD",
        "cdNm": "Algerian dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 46,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "EGP",
        "cdNm": "Egyptian pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 47,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ERN",
        "cdNm": "Eritrean nakfa",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 48,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ETB",
        "cdNm": "Ethiopian birr",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 49,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "EUR",
        "cdNm": "Euro",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 50,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FJD",
        "cdNm": "Fiji dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 51,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "FKP",
        "cdNm": "Falkland Islands pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 52,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GBP",
        "cdNm": "Pound sterling",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 53,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GEL",
        "cdNm": "Georgian lari",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 54,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GHS",
        "cdNm": "Ghanaian cedi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 55,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GIP",
        "cdNm": "Gibraltar pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 56,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GMD",
        "cdNm": "Gambian dalasi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 57,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GNF",
        "cdNm": "Guinean franc",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 58,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GTQ",
        "cdNm": "Guatemalan quetzal",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 59,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "GYD",
        "cdNm": "Guyanese dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 60,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HKD",
        "cdNm": "Hong Kong dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 61,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HNL",
        "cdNm": "Honduran lempira",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 62,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HRK",
        "cdNm": "Croatian kuna",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 63,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HTG",
        "cdNm": "Haitian gourde",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 64,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "HUF",
        "cdNm": "Hungarian forint",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 65,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IDR",
        "cdNm": "Indonesian rupiah",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 66,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ILS",
        "cdNm": "Israeli new shekel",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 67,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "INR",
        "cdNm": "Indian rupee",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 68,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IQD",
        "cdNm": "Iraqi dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 69,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "IRR",
        "cdNm": "Iranian rial",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 70,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ISK",
        "cdNm": "Icelandic króna",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 71,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JMD",
        "cdNm": "Jamaican dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 72,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JOD",
        "cdNm": "Jordanian dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 73,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "JPY",
        "cdNm": "Japanese yen",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 74,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KES",
        "cdNm": "Kenyan shilling",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 75,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KGS",
        "cdNm": "Kyrgyzstani som",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 76,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KHR",
        "cdNm": "Cambodian riel",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 77,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KMF",
        "cdNm": "Comoro franc",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 78,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KPW",
        "cdNm": "North Korean won",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 79,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KRW",
        "cdNm": "South Korean won",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 80,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KWD",
        "cdNm": "Kuwaiti dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 81,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KYD",
        "cdNm": "Cayman Islands dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 82,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "KZT",
        "cdNm": "Kazakhstani tenge",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 83,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LAK",
        "cdNm": "Lao kip",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 84,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LBP",
        "cdNm": "Lebanese pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 85,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LKR",
        "cdNm": "Sri Lankan rupee",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 86,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LRD",
        "cdNm": "Liberian dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 87,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LSL",
        "cdNm": "Lesotho loti",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 88,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "LYD",
        "cdNm": "Libyan dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 89,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MAD",
        "cdNm": "Moroccan dirham",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 90,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MDL",
        "cdNm": "Moldovan leu",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 91,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MGA",
        "cdNm": "Malagasy ariary",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 92,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MKD",
        "cdNm": "Macedonian denar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 93,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MMK",
        "cdNm": "Myanmar kyat",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 94,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MNT",
        "cdNm": "Mongolian tögrög",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 95,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MOP",
        "cdNm": "Macanese pataca",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 96,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MRO",
        "cdNm": "Mauritanian ouguiya",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 97,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MUR",
        "cdNm": "Mauritian rupee",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 98,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MVR",
        "cdNm": "Maldivian rufiyaa",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 99,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MWK",
        "cdNm": "Malawian kwacha",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 100,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MXN",
        "cdNm": "Mexican peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 101,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MXV",
        "cdNm": "Mexican Unidad de Inversion (UDI) (funds code)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 102,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MYR",
        "cdNm": "Malaysian ringgit",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 103,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "MZN",
        "cdNm": "Mozambican metical",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 104,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NAD",
        "cdNm": "Namibian dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 105,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NGN",
        "cdNm": "Nigerian naira",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 106,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NIO",
        "cdNm": "Nicaraguan córdoba",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 107,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NOK",
        "cdNm": "Norwegian krone",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 108,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NPR",
        "cdNm": "Nepalese rupee",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 109,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "NZD",
        "cdNm": "New Zealand dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 110,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "OMR",
        "cdNm": "Omani rial",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 111,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PAB",
        "cdNm": "Panamanian balboa",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 112,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PEN",
        "cdNm": "Peruvian Sol",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 113,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PGK",
        "cdNm": "Papua New Guinean kina",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 114,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PHP",
        "cdNm": "Philippine peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 115,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PKR",
        "cdNm": "Pakistani rupee",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 116,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PLN",
        "cdNm": "Polish zloty",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 117,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "PYG",
        "cdNm": "Paraguayan guaraní",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 118,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "QAR",
        "cdNm": "Qatari riyal",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 119,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RON",
        "cdNm": "Romanian leu",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 120,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RSD",
        "cdNm": "Serbian dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 121,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RUB",
        "cdNm": "Russian ruble",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 122,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "RWF",
        "cdNm": "Rwandan franc",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 123,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SAR",
        "cdNm": "Saudi riyal",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 124,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SBD",
        "cdNm": "Solomon Islands dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 125,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SCR",
        "cdNm": "Seychelles rupee",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 126,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SDG",
        "cdNm": "Sudanese pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 127,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SEK",
        "cdNm": "Swedish krona/kronor",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 128,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SGD",
        "cdNm": "Singapore dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 129,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SHP",
        "cdNm": "Saint Helena pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 130,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SLL",
        "cdNm": "Sierra Leonean leone",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 131,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SOS",
        "cdNm": "Somali shilling",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 132,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SRD",
        "cdNm": "Surinamese dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 133,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SSP",
        "cdNm": "South Sudanese pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 134,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "STD",
        "cdNm": "São Tomé and Príncipe dobra",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 135,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SVC",
        "cdNm": "Salvadoran colón",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 136,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SYP",
        "cdNm": "Syrian pound",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 137,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "SZL",
        "cdNm": "Swazi lilangeni",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 138,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "THB",
        "cdNm": "Thai baht",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 139,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TJS",
        "cdNm": "Tajikistani somoni",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 140,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TMT",
        "cdNm": "Turkmenistani manat",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 141,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TND",
        "cdNm": "Tunisian dinar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 142,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TOP",
        "cdNm": "Tongan pa?anga",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 143,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TRY",
        "cdNm": "Turkish lira",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 144,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TTD",
        "cdNm": "Trinidad and Tobago dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 145,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TWD",
        "cdNm": "New Taiwan dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 146,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "TZS",
        "cdNm": "Tanzanian shilling",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 147,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UAH",
        "cdNm": "Ukrainian hryvnia",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 148,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UGX",
        "cdNm": "Ugandan shilling",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 149,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "USD",
        "cdNm": "United States dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 150,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "USN",
        "cdNm": "United States dollar (next day) (funds code)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 151,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UYI",
        "cdNm": "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 152,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UYU",
        "cdNm": "Uruguayan peso",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 153,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "UZS",
        "cdNm": "Uzbekistan som",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 154,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VEF",
        "cdNm": "Venezuelan bolívar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 155,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VND",
        "cdNm": "Vietnamese dong",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 156,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "VUV",
        "cdNm": "Vanuatu vatu",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 157,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "WST",
        "cdNm": "Samoan tala",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 158,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XAF",
        "cdNm": "CFA franc BEAC",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 159,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XAG",
        "cdNm": "Silver (one troy ounce)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 160,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XAU",
        "cdNm": "Gold (one troy ounce)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 161,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XBA",
        "cdNm": "European Composite Unit (EURCO) (bond market unit)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 162,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XBB",
        "cdNm": "European Monetary Unit (E.M.U.-6) (bond market unit)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 163,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XBC",
        "cdNm": "European Unit of Account 9 (E.U.A.-9) (bond market unit)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 164,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XBD",
        "cdNm": "European Unit of Account 17 (E.U.A.-17) (bond market unit)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 165,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XCD",
        "cdNm": "East Caribbean dollar",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 166,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XDR",
        "cdNm": "Special drawing rights",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 167,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XOF",
        "cdNm": "CFA franc BCEAO",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 168,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XPD",
        "cdNm": "Palladium (one troy ounce)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 169,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XPF",
        "cdNm": "CFP franc (franc Pacifique)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 170,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XPT",
        "cdNm": "Platinum (one troy ounce)",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 171,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XSU",
        "cdNm": "SUCRE",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 172,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XTS",
        "cdNm": "Code reserved for testing purposes",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 173,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XUA",
        "cdNm": "ADB Unit of Account",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 174,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "XXX",
        "cdNm": "No currency",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 175,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "YER",
        "cdNm": "Yemeni rial",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 176,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ZAR",
        "cdNm": "South African rand",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 177,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ZMW",
        "cdNm": "Zambian kwacha",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 178,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "ZWL",
        "cdNm": "Zimbabwean dollar A/10",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 179,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "34",
    "cdClsNm": "Purchase Status",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "01",
        "cdNm": "Wait for Approval",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "02",
        "cdNm": "Approved",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "03",
        "cdNm": "Cancel Requested",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "04",
        "cdNm": "Canceled",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "05",
        "cdNm": "Refunded",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "06",
        "cdNm": "Transferred",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "35",
    "cdClsNm": "Reason of Inventory Adjustment",
    "cdClsDesc": "Only Client",
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "01",
        "cdNm": "Cargo Transit Out",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "02",
        "cdNm": "Cargo Transit In",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "03",
        "cdNm": "Adjustment In",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "04",
        "cdNm": "Adjustment Out",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "05",
        "cdNm": "Discarding",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "06",
        "cdNm": "Processing Raw",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "07",
        "cdNm": "Processing Result",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "36",
    "cdClsNm": "Bank",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "1",
        "cdNm": "MAYFAIR BANK LIMITED",
        "cdDesc": "MAYFAIR BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "2",
        "cdNm": "M-PAYMENT",
        "cdDesc": "M-PAYMENT",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "3",
        "cdNm": "KENYA POST OFFICE SAVINGS BANK",
        "cdDesc": "KENYA POST OFFICE SAVINGS BANK",
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "4",
        "cdNm": "FIRST COMMUNITY BANKS LIMITED",
        "cdDesc": "FIRST COMMUNITY BANKS LIMITED",
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "5",
        "cdNm": "GULF AFRICAN BANK LTD",
        "cdDesc": "GULF AFRICAN BANK LTD",
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "6",
        "cdNm": "FAMILY BANK LTD",
        "cdDesc": "FAMILY BANK LTD",
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "7",
        "cdNm": "EQUITY BANK LIMITED",
        "cdDesc": "EQUITY BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "8",
        "cdNm": "SIDIAN BANK LIMITED",
        "cdDesc": "SIDIAN BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 8,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "9",
        "cdNm": "DIAMOND TRUST BANK LIMITED",
        "cdDesc": "DIAMOND TRUST BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 9,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "10",
        "cdNm": "HOUSING FINANCE BANK",
        "cdDesc": "HOUSING FINANCE BANK",
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "11",
        "cdNm": "SBM BANK (KENYA) LIMITED",
        "cdDesc": "SBM BANK (KENYA) LIMITED",
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "12",
        "cdNm": "DEVELOPMENT BANK OF KENYA LIMITED",
        "cdDesc": "DEVELOPMENT BANK OF KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "13",
        "cdNm": "I&M BANK LIMITED",
        "cdDesc": "I&M BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "14",
        "cdNm": "GUARDIAN BANK LIMITED",
        "cdDesc": "GUARDIAN BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 14,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "15",
        "cdNm": "VICTORIA COMMERCIAL BANK LIMITED",
        "cdDesc": "VICTORIA COMMERCIAL BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 15,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "16",
        "cdNm": "GUARANTY TRUST BANK KENYA LIMITED",
        "cdDesc": "GUARANTY TRUST BANK KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 16,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "17",
        "cdNm": "JAMII BORA BANK",
        "cdDesc": "JAMII BORA BANK",
        "useYn": "Y",
        "srtOrd": 17,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "18",
        "cdNm": "PARAMOUNT UNIVERSAL BANK LIMITED",
        "cdDesc": "PARAMOUNT UNIVERSAL BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 18,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "19",
        "cdNm": "EQUATORIAL COMMERCIAL HOLDING LIMITED",
        "cdDesc": "EQUATORIAL COMMERCIAL HOLDING LIMITED",
        "useYn": "Y",
        "srtOrd": 19,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "20",
        "cdNm": "GIRO COMMERCIAL BANK LIMITED",
        "cdDesc": "GIRO COMMERCIAL BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 20,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "21",
        "cdNm": "NIC BANK LIMITED",
        "cdDesc": "NIC BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 21,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "22",
        "cdNm": "IMPERIAL BANK LIMITED",
        "cdDesc": "IMPERIAL BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 22,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "23",
        "cdNm": "CFC STANBIC BANK KENYA LIMITED",
        "cdDesc": "CFC STANBIC BANK KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 23,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "24",
        "cdNm": "CHASE BANK LIMITED",
        "cdDesc": "CHASE BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 24,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "25",
        "cdNm": "ACCESS BANK (KENYA) PLC",
        "cdDesc": "ACCESS BANK (KENYA) PLC",
        "useYn": "Y",
        "srtOrd": 25,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "26",
        "cdNm": "CREDIT BANK LIMITED",
        "cdDesc": "CREDIT BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 26,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "27",
        "cdNm": "CONSOLIDATED BANK OF KENYA LIMITED",
        "cdDesc": "CONSOLIDATED BANK OF KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 27,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "28",
        "cdNm": "DUBAI BANK KENYA LIMITED",
        "cdDesc": "DUBAI BANK KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 28,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "29",
        "cdNm": "BANK OF AFRICA KENYA LIMITED",
        "cdDesc": "BANK OF AFRICA KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 29,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "30",
        "cdNm": "MIDDLE EAST BANK KENYA LIMITED",
        "cdDesc": "MIDDLE EAST BANK KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 30,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "31",
        "cdNm": "HABIB BANK A.G. ZURICH",
        "cdDesc": "HABIB BANK A.G. ZURICH",
        "useYn": "Y",
        "srtOrd": 31,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "32",
        "cdNm": "CITIBANK N.A.",
        "cdDesc": "CITIBANK N.A.",
        "useYn": "Y",
        "srtOrd": 32,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "33",
        "cdNm": "ORIENTAL COMMERCIAL BANK LIMITED",
        "cdDesc": "ORIENTAL COMMERCIAL BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 33,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "34",
        "cdNm": "NATIONAL BANK OF KENYA LTD",
        "cdDesc": "NATIONAL BANK OF KENYA LTD",
        "useYn": "Y",
        "srtOrd": 34,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "35",
        "cdNm": "PRIME BANK LIMITED",
        "cdDesc": "PRIME BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 35,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "36",
        "cdNm": "CENTRAL BANK OF KENYA",
        "cdDesc": "CENTRAL BANK OF KENYA",
        "useYn": "Y",
        "srtOrd": 36,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "37",
        "cdNm": "HABIB BANK LIMITED",
        "cdDesc": "HABIB BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 37,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "38",
        "cdNm": "COMMERCIAL BANK OF AFRICA LIMITED",
        "cdDesc": "COMMERCIAL BANK OF AFRICA LIMITED",
        "useYn": "Y",
        "srtOrd": 38,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "39",
        "cdNm": "BANK OF BARODA KENYA LIMITED",
        "cdDesc": "BANK OF BARODA KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 39,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "40",
        "cdNm": "BANK OF INDIA",
        "cdDesc": "BANK OF INDIA",
        "useYn": "Y",
        "srtOrd": 40,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "41",
        "cdNm": "ABSA BANK KENYA PLC",
        "cdDesc": "ABSA BANK KENYA PLC",
        "useYn": "Y",
        "srtOrd": 41,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "42",
        "cdNm": "STANDARD CHARTERED BANK KENYA LIMITED",
        "cdDesc": "STANDARD CHARTERED BANK KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 42,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "43",
        "cdNm": "KENYA COMMERCIAL BANK LTD",
        "cdDesc": "KENYA COMMERCIAL BANK LTD",
        "useYn": "Y",
        "srtOrd": 43,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "44",
        "cdNm": "AFRICAN BANKING CORP BANK LTD",
        "cdDesc": "AFRICAN BANKING CORP BANK LTD",
        "useYn": "Y",
        "srtOrd": 44,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "45",
        "cdNm": "ECO BANK LIMITED",
        "cdDesc": "ECO BANK LIMITED",
        "useYn": "Y",
        "srtOrd": 45,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "46",
        "cdNm": "UBA KENYA BANK LTD",
        "cdDesc": "UBA KENYA BANK LTD",
        "useYn": "Y",
        "srtOrd": 46,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "47",
        "cdNm": "CO-OPERATIVE BANK OF KENYA LIMITED",
        "cdDesc": "CO-OPERATIVE BANK OF KENYA LIMITED",
        "useYn": "Y",
        "srtOrd": 47,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "37",
    "cdClsNm": "Sales Receipt Type",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "S",
        "cdNm": "Invoice",
        "cdDesc": "Invoice",
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "R",
        "cdNm": "Credit Note",
        "cdDesc": "Credit Note",
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "38",
    "cdClsNm": "Purchase Receipt Type",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "P",
        "cdNm": "Invoice",
        "cdDesc": "Invoice",
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "R",
        "cdNm": "Credit Note",
        "cdDesc": "Credit Note",
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "45",
    "cdClsNm": "Tax Office",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "501",
        "cdNm": "HQ",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "52",
        "cdNm": "Head Quarters",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "30",
        "cdNm": "Nairobi Station 1",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "18",
        "cdNm": "KITUI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "37",
        "cdNm": "SAMBURU",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 5,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "19",
        "cdNm": "KWALE",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 6,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "25",
        "cdNm": "MARSABIT",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 7,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "27",
        "cdNm": "MIGORI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 8,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "28",
        "cdNm": "MOMBASA",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 9,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "32",
        "cdNm": "NANDI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 10,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "33",
        "cdNm": "NAROK",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 11,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "34",
        "cdNm": "NYAMIRA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 12,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "38",
        "cdNm": "SIAYA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 13,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "57",
        "cdNm": "Western Region",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 14,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "16",
        "cdNm": "West of Nairobi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 15,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "60",
        "cdNm": "LODWAR",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 16,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "14",
        "cdNm": "Thika",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 17,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "56",
        "cdNm": "Southern Region",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 18,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "13",
        "cdNm": "South of Nairobi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 19,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "45",
        "cdNm": "VIHIGA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 20,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "20",
        "cdNm": "LAIKIPIA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 21,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "23",
        "cdNm": "MAKUENI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 22,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "35",
        "cdNm": "NYANDARUA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 23,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "39",
        "cdNm": "TAITA TAVETA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 24,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "40",
        "cdNm": "TANA RIVER",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 25,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "42",
        "cdNm": "TRANS NZOIA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 26,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "48",
        "cdNm": "Nairobi Station 2",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 27,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "43",
        "cdNm": "TURKANA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 28,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "44",
        "cdNm": "UASIN GISHU",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 29,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "47",
        "cdNm": "WEST POKOT",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 30,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "29",
        "cdNm": "Murang'a",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 31,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "41",
        "cdNm": "Tharaka-Nithi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 32,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "97",
        "cdNm": "RAC NORTHERN",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 33,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "98",
        "cdNm": "RAC NORTH",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 34,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "54",
        "cdNm": "Northern Region",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 35,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "12",
        "cdNm": "North of Nairobi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 36,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "36",
        "cdNm": "Nyeri",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 37,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "58",
        "cdNm": "Nairobi Region",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 38,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "72",
        "cdNm": "RAC CENTRAL",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 39,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "10",
        "cdNm": "Mombasa South",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 40,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "9",
        "cdNm": "Mombasa North",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 41,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "26",
        "cdNm": "Meru",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 42,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "8",
        "cdNm": "Malindi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 43,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "22",
        "cdNm": "Machakos",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 44,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "17",
        "cdNm": "Kisumu",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 45,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "7",
        "cdNm": "Kisii",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 46,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "6",
        "cdNm": "Kakamega",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 47,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "95",
        "cdNm": "PREMIER TAX OFFICE",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 48,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "59",
        "cdNm": "EASTLEIGH",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 49,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "2",
        "cdNm": "East of Nairobi",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 50,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "3",
        "cdNm": "Eldoret",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 51,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "4",
        "cdNm": "Embu",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 52,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "53",
        "cdNm": "Central Region",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 53,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "1",
        "cdNm": "BUNGOMA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 54,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "5",
        "cdNm": "GARISSA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 55,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "11",
        "cdNm": "Nairobi South",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 56,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "24",
        "cdNm": "MANDERA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 57,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "96",
        "cdNm": "PREMIER TAX OFFICE REGION",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 58,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "65",
        "cdNm": "LTO Region",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 59,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "66",
        "cdNm": "MTO Region",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 60,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "49",
        "cdNm": "LTO",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 61,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "50",
        "cdNm": "MTO",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 62,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "62",
        "cdNm": "LTO",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 63,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "63",
        "cdNm": "MTO",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 64,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "73",
        "cdNm": "RAC SAMEER",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 65,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "68",
        "cdNm": "KRAHP",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 66,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "69",
        "cdNm": "PUBLIC SECTOR DIVISION REGION",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 67,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "70",
        "cdNm": "PUBLIC SECTOR DIVISION",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 68,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "15",
        "cdNm": "VOI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 69,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "74",
        "cdNm": "RAC LTO",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 70,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "75",
        "cdNm": "RAC MTO",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 71,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "76",
        "cdNm": "RAC PSD",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 72,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "78",
        "cdNm": "RAC SOUTHERN",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 73,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "79",
        "cdNm": "RAC WEST",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 74,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "80",
        "cdNm": "RAC WESTERN",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 75,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "77",
        "cdNm": "RAC NORTH RIFTVALLEY",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 76,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "81",
        "cdNm": "RAC SOUTH RIFTVALLEY",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 77,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "55",
        "cdNm": "NORTH RIFT VALLEY REGION",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 78,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "82",
        "cdNm": "SOUTH RIFT VALLEY REGION",
        "cdDesc": null,
        "useYn": "N",
        "srtOrd": 79,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "51",
        "cdNm": "KERICHO",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 80,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "31",
        "cdNm": "NAKURU",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 81,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "86",
        "cdNm": "KERUGOYA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 82,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "87",
        "cdNm": "MURANGA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 83,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "88",
        "cdNm": "NANYUKI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 84,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "89",
        "cdNm": "KIAMBU",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 85,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "83",
        "cdNm": "KITALE",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 86,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "64",
        "cdNm": "ISIOLO",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 87,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "90",
        "cdNm": "KAJIADO",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 88,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "91",
        "cdNm": "KITUI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 89,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "92",
        "cdNm": "MOYALE",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 90,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "46",
        "cdNm": "WAJIR",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 91,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "85",
        "cdNm": "MARALAL",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 92,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "61",
        "cdNm": "NAIVASHA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 93,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "67",
        "cdNm": "NAROK",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 94,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "84",
        "cdNm": "NYAHURURU",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 95,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "93",
        "cdNm": "HOMABAY",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 96,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "94",
        "cdNm": "BUSIA",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 97,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "21",
        "cdNm": "LAMU",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 98,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "99",
        "cdNm": "DIANI",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 99,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "48",
    "cdClsNm": "LOCALE",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": "Language",
    "userDfnNm2": "Country",
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "en_GB",
        "cdNm": "English",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": "en",
        "userDfnCd2": "GB",
        "userDfnCd3": null
      },
      {
        "cd": "en_sw",
        "cdNm": "Kiswahili",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": "en",
        "userDfnCd2": "SW",
        "userDfnCd3": null
      },
      {
        "cd": "fr_FR",
        "cdNm": "French",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": "fr",
        "userDfnCd2": "FR",
        "userDfnCd3": null
      },
      {
        "cd": "ko_KR",
        "cdNm": "???",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": "ko",
        "userDfnCd2": "KR",
        "userDfnCd3": null
      }
    ]
  },
  {
    "cdCls": "49",
    "cdClsNm": "Category Level",
    "cdClsDesc": null,
    "useYn": "Y",
    "userDfnNm1": null,
    "userDfnNm2": null,
    "userDfnNm3": null,
    "dtlList": [
      {
        "cd": "1",
        "cdNm": "Sagement",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 1,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "2",
        "cdNm": "Family",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 2,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "3",
        "cdNm": "Class",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 3,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      },
      {
        "cd": "4",
        "cdNm": "Commodity",
        "cdDesc": null,
        "useYn": "Y",
        "srtOrd": 4,
        "userDfnCd1": null,
        "userDfnCd2": null,
        "userDfnCd3": null
      }
    ]
  }
]
